
import React from "react";
import {Field, Form} from "react-final-form";
import DateTime from "react-datetime";
import Moment from "moment";
import createDecorator from "final-form-calculate";

const uiSchema = {
    "quantity": {
        "ui:autofocus": true
    },
    itemOrderId: {
        "ui:widget": "hidden"
    }
};

const schema = {
    type: "object",
    required: ["quantity"],
    properties: {
        quantity: {type: "number", title: "Quantity"},
        unitCost: {type: "number", title: "Unit Cost"},
        itemOrderId: {type: "number"}
    }
};

interface RSFProps {
    onSubmit: (formData: object) => void
    formData: object;
    onCancel: () => void;
}


interface DFProps {
    name: string;
    value?: string;
    onSubmit: (values: object) => any
}


export const ReceiveStockForm = ({formData, onCancel, onSubmit}: RSFProps) => {
    const calculator = createDecorator({
        field: 'dateTime-field',
        updates: {
            // @ts-ignore
            dateTime: (ignoredValue, allValues: AllValues) => {
                const dateTime = allValues['dateTime-field'];

                if (Moment.isMoment(dateTime)) {
                    return dateTime.format("YYYY-MM-DD HH:mm:ss");
                }
            }
        }
    });

    return (
        <Form
            
            decorators={[calculator]} initialValues={formData} onSubmit={(values) => {
            onSubmit(values)
        }}>
            {({handleSubmit, values, submitting, valid, dirty}) => {
                return (
                    <form onSubmit={handleSubmit}>

                        <div className="form-group">
                            <label htmlFor="quantity">Quantity</label>

                            <Field id='quantity' className="form-control" component='input' type='number'
                                   name='quantity'/>
                        </div>

                        <div className="form-group">
                            <label htmlFor="unit-cost">Unit Cost</label>

                            <Field id='unit-cost' className="form-control" component='input' type='number'
                                   name='unitCost'/>

                        </div>

                        <div className="form-group">
                            <label>Date</label>

                            <Field
                                name={'dateTime-field'}

                                validate={(value) => {
                                    console.log(value)
                                    if (!Moment.isMoment(value)) {
                                        return 'Must be a valid date'
                                    }

                                    return undefined;
                                }}
                            >

                                {props => (
                                    <DateTime
                                        value={props.input.value}
                                        onChange={props.input.onChange}
                                    />
                                )
                                }
                            </Field>
                        </div>

                        <div className="clearfix">
                            <div className="btn-toolbar pull-right">
                                <button type="submit" className="btn btn-primary">Submit</button>
                                <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}
