import React, {PureComponent} from "react";
import {Field, Form} from "react-final-form";
import createDecorator from "final-form-calculate";
import Decimal from "decimal.js";

export const renderField = ({id, input, label, disabled, type, meta: {touched, submitError, submitFailed}}) => {
    const className = submitFailed && submitError ? "form-control is-invalid" : "form-control";

    return (
        <div className="form-group">
            <label>{label}</label>
            <input disabled={disabled} className={className} {...input} placeholder={label}/>
            {/*<div className="invalid-feedback">*/}
            {/*    {submitError}*/}
            {/*</div>*/}
        </div>
    )
}

export default class StocktakeForm extends PureComponent {

    render() {
        const {onSubmit, onCancel, locations} = this.props;

        const createLocationId = (id) => `s${id}`;

        const calculations = [
            {
                field: /locations\.s\d/,
                updates: {
                    'quantity': (ignoredValue, allValues) => {
                        console.log(allValues)

                        const values = locations.map(({stocklocationid}) => (allValues.locations[createLocationId(stocklocationid)]));

                        return `${values.reduce((sum, value) => sum.add(new Decimal(value || 0)), new Decimal(0))}`
                    }
                },
            }
        ];

        const calculator = createDecorator(...calculations);


        return (
            <Form
                // mutators={{...arrayMutators}}
                // initialValues={answers}


                onSubmit={onSubmit}

                decorators={[calculator]}
                subscription={{submitting: true, pristine: true, values: true}}

            >
                {({handleSubmit, pristine, submitting, submitFailed, values, active}) => {
                    return (
                        <form onSubmit={handleSubmit}>

                            {
                                locations.map(({stocklocationid, description}) => {
                                    return (

                                        <Field
                                            label={description}
                                            type={"number"}
                                            component={renderField}
                                            className={"form-control"}
                                            name={`locations.${createLocationId(stocklocationid)}`}

                                            required
                                        />
                                    )
                                })
                            }

                            <Field
                                label={locations.length ? "Total" : "Quantity"}
                                type="number"
                                component={renderField}
                                tabIndex={-1}
                                className="form-control"
                                name={"quantity"}
                                readOnly
                            />

                            <div className="clearfix">
                                <div className="btn-group pull-right">
                                    <button type="submit" className="btn btn-primary">Update</button>
                                    <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Form>
        )
    }
}
