import React, {Component} from "react";
import events from "../db/events";
import accounting from "accounting";
import PropTypes from 'prop-types';

export default class ActionBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            float: accounting.formatMoney(0)
        };

        this.handleShiftEnd = this.handleShiftEnd.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
    }


    handleShiftEnd() {
        // TODO add exception

        this.props.onShiftEnd();
    }

    handleModalClose() {
        this.setState({
            showModal: false
        });
    }

    render() {
        let syncIcon;
        if (this.props.syncing) {
            syncIcon =
                <span className="pos-action">Sync <span className="glyphicon glyphicon-refresh refresh-spin"/></span>;
        } else if (this.props.syncError) {
            syncIcon =
                <span className="pos-action text-danger">Sync <span className="glyphicon glyphicon-refresh"/></span>;
        } else {
            syncIcon = <span className="pos-action">Sync <span className="glyphicon glyphicon-refresh"/></span>;
        }

        let shiftControl;
        if (this.props.shiftActive) {
            shiftControl = <span onClick={this.handleShiftEnd} className="pos-action">End Shift</span>
        } else {
            shiftControl = <span onClick={this.props.onShiftStart} className="pos-action">Start Shift</span>
        }

        return (
            <div className="pos-action-bar">
                <div className="pos-actions-left">
                    {syncIcon}
                    <span onClick={this.props.onLockScreen} className="pos-action">Lock Screen</span>
                    {shiftControl}
                </div>
            </div>
        );
    }
}

ActionBar.propTypes = {
    saleActive: PropTypes.bool,
    shiftActive: PropTypes.bool,
    onShiftStart: PropTypes.func,
    itemsAdded: PropTypes.bool,
    syncError: PropTypes.bool,
    syncing: PropTypes.bool,
    onLockScreen: PropTypes.func,
    cashierId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onShiftEnd: PropTypes.func
};
