/**
 * Created by RDTS on 31/03/2017.
 */
import Dexie from 'dexie';

const db = new Dexie("WebPOS");

db.version(1).stores({sales: "id++"});
db.version(2).stores({sales: "id++", items: "siteId"});
db.version(3).stores({sales: "id++", items: "siteId", events: "id++"});
db.version(4).stores({sales: "id++", items: "siteId", events: "id++", params: 'key'});
db.version(5).stores({sales: "id++", items: "siteId", events: "id++, eventType", params: 'key'});
db.version(6).stores({sales: "id++", items: "siteId", events: "id++, eventType, time", params: 'key'});
db.version(7).stores({sales: "id++, transactionNumber", items: "siteId", events: "id++, eventType, time", params: 'key'});
db.version(8).stores({sales: "id++, transactionNumber, time", items: "siteId", events: "id++, eventType, time", params: 'key'});

db.open().catch(function (error) {
    alert(error);
});

export default db;
