import React from 'react';
import ReactDOM from 'react-dom';
import {Report} from "./Report";
import {Provider} from "react-redux";
import store from "../../app/store";

export default () => {
    const container = document.getElementById('report');

    if (container) {
        // const siteId = container.getAttribute('data-site-id');
        ReactDOM.render(
            <Provider store={store}>
                <Report/>
            </Provider>,
            container
        )
    }
};
