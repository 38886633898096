import React from 'react';
import ReactDOM from 'react-dom';
import PaymentAllocation from "./PaymentAllocation";

export default () => {
    const container = document.getElementById('payment-allocation');

    if (container) {
        const siteId = container.getAttribute('data-site-id');

        ReactDOM.render(
            <PaymentAllocation siteId={siteId}/>,
            container
        )
    }
};
