import React, {Component} from "react";
// import printJS from 'print.js';
import accounting from "accounting";
// import "./Receipt.css";
import PropTypes from 'prop-types';

const ReceiptLine = ({leftField, rightField, leftBold, rightBold}) => (
    <div className="clearfix">
        <span>
            { leftBold ? <strong>{leftField}</strong> : leftField }
        </span>
        <span className="pull-right">
            { rightBold ? <strong>{rightField}</strong> : rightField }
        </span>
    </div>
);

ReceiptLine.propTypes = {
    leftField: PropTypes.any.isRequired,
    rightField: PropTypes.any.isRequired,
    leftBold: PropTypes.bool,
    rightBold: PropTypes.bool,
};

export default class Receipt extends Component {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        window.print();
    }

    cashierFullName() {
        const firstName = this.props.cashierFirstName;
        const lastName = this.props.cashierLastName;

        if (firstName) {
            // if there is a last name return first name and last name otherwise return first name
            return lastName ? firstName + ' ' + lastName : firstName;
        } else if (lastName) {
            // last name only
            return lastName;
        }
        // no name
        return '';
    }

    render() {
        // generate item list
        const items = this.props.cart.map((item, index) => {
            if (item.qty > 1) {
                // render unit price line if quantity of a line item is more than one
                return (
                    <div className="clearfix" key={index}>
                        <div>{item.name}</div>
                        <div className="clearfix">
                        <span style={{marginLeft: "2em"}}>{item.qty}&nbsp;
                            @&nbsp;{accounting.formatMoney(item.unitPrice)}</span>
                            <span className="pull-right">{accounting.formatMoney(item.totalPrice)}</span>
                        </div>
                    </div>
                );
            } else {
                // render item name followed by total price if the line item has a quantity of one
                return <ReceiptLine key={index} leftField={item.name} rightField={accounting.formatMoney(item.totalPrice)}/>
            }
        });

        // generate payment list
        const payments = this.props.payments.map((payment, index) => {
            // if the payment was by card add the details after the payment in the receipt payments block
            let organisationName;
            if (payment.organisationName) {
                organisationName = <div style={{marginLeft: "2em"}}>{payment.organisationName}</div>;
            }

            let cardNumber;
            if (payment.cardNumber) {
                cardNumber = <div style={{marginLeft: "2em"}}>
                    <span>Card Number:</span>&nbsp;<strong>{payment.cardNumber}</strong>
                </div>;
            }

            return (
                <div className="clearfix" key={index}>
                    <span>{payment.description}</span>
                    <span className="pull-right">{accounting.formatMoney(payment.amount)}</span>
                    {organisationName}
                    {cardNumber}
                </div>
            );
        });

        // split address into lines - could be done through styling
        const address = this.props.address.split('\n').map((addressLine, index) => {
            return <div key={index}>{addressLine}</div>
        });

        // split receipt header into lines
        const receiptHeader = this.props.receiptHeader.split('\n').map((receiptHeaderLine, index) => {
            return <div key={index}>{receiptHeaderLine}</div>
        });

        // split receipt footer into lines
        const receiptFooter = this.props.receiptFooter.split('\n').map((receiptFooterLine, index) => {
            return <div key={index}>{receiptFooterLine}</div>
        });

        const date = new Date(this.props.time);

        // only create rounding block if the rounding amount does not equal 0
        let rounding;
        if (this.props.rounding) {
            rounding = <div className="receipt-block">
                <ReceiptLine
                    leftField={"Rounding"}
                    rightField={accounting.formatMoney(this.props.rounding)}
                    leftBold={true}
                />
            </div>
        }

        const cashierName = this.cashierFullName();
        let cashier;

        if (cashierName) {
            cashier = <ReceiptLine leftField={"Cashier: "} rightField={cashierName} rightBold={true}/>
        }

        let changeBlock;
        if (this.props.change !== 0) {
            changeBlock = <ReceiptLine
                leftField={"Change"}
                rightField={accounting.formatMoney(this.props.change)}
                leftBold={true}
            />
        }

        return (
            <div className="print-receipt">

                {/* receipt header block */}
                {receiptHeader}
                <div style={{textAlign: "center", fontSize: "small"}}>
                    { this.props.duplicate ? 'Duplicate Tax CustomerInvoice' : 'Tax CustomerInvoice' }
                </div>
                <hr />
                {/* site name, address, tax number block */}
                <div className="receipt-block">
                    <h4>{this.props.siteName}</h4>
                    <div>{address}</div>
                    <ReceiptLine leftField={"GST#:"} rightField={this.props.taxNumber} rightBold={true}/>
                </div>


                <hr />

                {/* transaction no, cashier, time block */}
                <div className="receipt-block">
                    <ReceiptLine
                        leftField={"CustomerInvoice Number:"}
                        rightField={this.props.transactionNumber}
                        rightBold={true}
                    />

                    { cashier }

                    <ReceiptLine
                        leftField={"Time:"}
                        rightField={date.toLocaleTimeString(this.props.locale)}
                        rightBold={true}
                    />

                    <ReceiptLine
                        leftField={"Date:"}
                        rightField={date.toLocaleDateString(this.props.locale)}
                        rightBold={true}
                    />
                </div>

                <hr />

                {/* items block */}
                <div className="receipt-block">
                    {/* items */}
                    <div>{items}</div>
                    {/* subtotal */}
                    <ReceiptLine
                        leftField={"Subtotal"}
                        rightField={accounting.formatMoney(this.props.saleTotal)}
                        leftBold={true}
                    />
                </div>

                {/* rounding block */}
                { rounding }

                {/* totals block */}
                <div className="receipt-block">{payments}</div>
                <div className="receipt-block">
                    {/* total */}
                    <ReceiptLine
                        leftField={"Total"}
                        rightField={accounting.formatMoney(this.props.totalPaid)}
                        leftBold={true}
                    />

                    <ReceiptLine
                        leftField={"GST"}
                        rightField={accounting.formatMoney(this.props.taxTotal)}
                        leftBold={true}
                    />

                    { changeBlock }
                </div>
                {/* receipt footer block */}
                { receiptFooter }
            </div>
        );
    }
}

Receipt.propTypes = {
    cart: PropTypes.arrayOf(PropTypes.object),
    payments: PropTypes.arrayOf(PropTypes.object),
    taxTotal: PropTypes.number,
    time: PropTypes.number,
    totalPaid: PropTypes.number,
    saleTotal: PropTypes.number,
    change: PropTypes.number,
    taxNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    rounding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    transactionNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    address: PropTypes.string,
    siteName: PropTypes.string,
    locale: PropTypes.string,
    receiptHeader: PropTypes.string,
    receiptFooter: PropTypes.string,
    cashierFirstName: PropTypes.string,
    cashierLastName: PropTypes.string,
    duplicate: PropTypes.bool
}
