import axios from "../utils/axios";

export interface POS {

}


export async function getPosList(siteId: number) {
    let url = `report/${siteId}/pos`;


    const {data} = await axios.get<POS[]>(url);
    return data
}
