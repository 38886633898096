import React from 'react';
import {ReceiveStockForm} from "./ReceiveStockForm";
import connect from "../connect";
import PropTypes from 'prop-types';
import ReceiveStockPriceAdjust from "./ReceiveStockPriceAdjust";
import {Modal} from "react-bootstrap";
import {compose} from "recompose";
import {TankDropSelectModalContent} from "./TankDropSelectModalContent";

export const ItemOrderReceive = (props) => {
    const {itemOrderFetch, closeModal, postStockResponse} = props;

    if (itemOrderFetch.pending) {
        return <Modal.Body>
            loading
        </Modal.Body>
    } else if (itemOrderFetch.rejected) {
        return <Modal.Body>
            error
        </Modal.Body>
    } else {
        const itemOrder = itemOrderFetch.value;

        const {tanks, unsuppliedQuantity, unitcost, itemorderid, itemid} = itemOrder;


        let formId = "wetstock-form";


        if (postStockResponse && postStockResponse.fulfilled) {
            return (
                <ReceiveStockPriceAdjust
                    onAdjusted={() => {
                        props.onChange();
                        closeModal();
                    }}
                    onCancel={closeModal}
                    itemId={itemid}
                />
            );
        } else {
            let content = null;

            if (tanks && tanks.length > 0) {
                content =
                    <TankDropSelectModalContent
                        gradeName={itemOrder.itemName}
                        onSubmit={() => {
                            closeModal();
                            props.onChange();
                        }}
                        itemOrderId={itemorderid}
                        tanks={tanks}
                        unsuppliedQuantity={unsuppliedQuantity}
                        onCancel={closeModal}
                        formId={formId}/>
            } else {
                content =
                    <Modal.Body>

                        <ReceiveStockForm
                            formData={{quantity: unsuppliedQuantity, unitCost: unitcost, itemOrderId: itemorderid}}
                            onSubmit={props.postDrystock}
                            onCancel={closeModal}
                        />
                    </Modal.Body>
            }

            return (
                <React.Fragment>
                    <Modal.Header closeButton>
                        <Modal.Title>{itemOrder.itemName}</Modal.Title>
                    </Modal.Header>
                    {content}
                </React.Fragment>
            )
        }
    }
};

const ItemOrderReceiveWithFetch = compose(
    connect((props) => {
        const {onChange, itemorderid} = props;

        let refreshItemOrder = () => {
            return {
                itemOrderFetch: {
                    url: `/supplierorder/item-order/${itemorderid}`,
                    // comparison: `${itemorderid}`,
                    force: true,
                    refreshing: true,
                    andThen: () => {
                        onChange();
                        return {};
                    },
                    // TODO

                }
            }
        };

        return {
            refreshItemOrder: refreshItemOrder,
            itemOrderFetch: {
                comparison: 1,
                url: `/supplierorder/item-order/${itemorderid}`,
            },
            postDrystock: (formData) => {
                return {
                    postStockResponse: {
                        method: 'POST',
                        url: `/itemorder/receive`,
                        body: JSON.stringify(formData),
                        andThen: refreshItemOrder,
                    }
                }
            },
        }
    })
)(ItemOrderReceive);

ItemOrderReceiveWithFetch.propTypes = {
    itemorderid: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default ItemOrderReceiveWithFetch;
