import React, {Component} from "react";
import ModalForm from "./ModalForm";
import PropTypes from 'prop-types';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loginFailed: false,
            passwordReadonly: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
    }

    componentDidMount() {
        this.setState({
            username: '',
            password: '',
        })
    }

    handleSubmit() {
        this.setState({
            loginFailed: !this.props.onLogin({
                username: this.state.username,
                password: this.state.password
            })
        });
    }

    handleChangeUsername(e) {
        this.setState({
            username: e.target.value
        });
    }

    handleChangePassword(e) {
        this.setState({
            password: e.target.value
        });
    }

    render() {
        let passwordForgot;
        if (this.state.loginFailed) {
            passwordForgot = <div
                id="password-forgot"
                className="text-center text-danger"
            >
                Username or password incorrect.
            </div>;
        }

        return (
            <ModalForm title="Cashier Login" onSubmit={this.handleSubmit} submitText="Login">
                <div className="form-group form-group-lg required">
                    <label className="control-label" htmlFor="loginform-username">Cashier</label>
                    <input
                        type="text"
                        id="loginform-username"
                        className="form-control"
                        value={this.state.username}
                        onChange={this.handleChangeUsername}
                        aria-required="true"
                    />
                </div>
                <div className="form-group form-group-lg required">
                    <label className="control-label" htmlFor="loginform-password">Password</label>
                    <input
                        type="password"
                        id="loginform-password"
                        className="form-control"
                        name="LoginForm[password]"
                        aria-required="true"
                        value={this.state.password}
                        onChange={this.handleChangePassword}
                    />
                </div>
                {passwordForgot}
            </ModalForm>
        );
    }
}

Login.propTypes = {
    onLogin: PropTypes.func
};
