import {Trash, Save} from "react-feather";
import React from "react";
import {Field, Form} from "react-final-form";
import {ItemOrder, TaxRate} from "./OrderForm";
import {AutoSave} from "../common/FinalFormUtils";

interface IORProps {
    taxRates: TaxRate[];
    itemOrder: ItemOrder;
    editable: boolean;
    canSupply: boolean;
    onReceive: (itemOrderId: number) => void
    onDelete: (itemOrderId: number) => void
    onUpdate: (itemOrderId: number, formData: object) => void
}


const ReadOnlyInput = ({value, right}: { value: any; right?: boolean }) => {
    return <input type="text" readOnly className={`form-control-plaintext${right ? " text-right" : ""}`} value={value}/>
}

export const ItemOrderRow = ({itemOrder, editable, canSupply, onReceive, onDelete, onUpdate, taxRates}: IORProps) => {
    let itemOrderId = itemOrder.itemorderid;

    const cells = [
        <td key='name'>
            <ReadOnlyInput value={itemOrder.itemName}/>
        </td>,
        <td key='qty'>
            <ItemOrderRowForm
                itemOrder={itemOrder}
                onSubmit={(formData => onUpdate(itemOrderId, formData))}
                delay={1500}
            >
                {editable ?
                    <Field
                        name='orderqty'
                        type='number'
                        component="input"
                        step="0.0001"
                        className="form-control"
                    />
                    :
                    <ReadOnlyInput value={itemOrder.orderqty}/>

                }

            </ItemOrderRowForm>
        </td>,
        <td key='cost'>
            <ItemOrderRowForm
                itemOrder={itemOrder}
                onSubmit={(formData => onUpdate(itemOrderId, formData))}
                delay={1500}
            >
                {editable ?
                    <Field
                        name='unitcost'
                        type='number'
                        component="input"
                        step="0.0001"
                        className="form-control"
                    />
                    :
                    <ReadOnlyInput value={itemOrder.unitcost}/>
                }
            </ItemOrderRowForm>
        </td>,
    ];

    if (!editable) {
        cells.push(<td key='supplyQty'><ReadOnlyInput value={itemOrder.suppliedQuantity}/></td>)
    }

    cells.push(
        <td key='taxRate'>
            <ItemOrderRowForm
                itemOrder={itemOrder}
                onSubmit={(formData => onUpdate(itemOrderId, formData))}
                delay={0}
            >
                <Field
                    className="form-control"
                    name="taxRateId"
                    component="select"
                >
                    {
                        taxRates.map(({description, taxrateid}) => {
                            return (
                                <option value={taxrateid}>{description}</option>
                            )
                        })
                    }
                </Field>
            </ItemOrderRowForm>
        </td>,
        <td className="text-right" key='total'><ReadOnlyInput right value={itemOrder.total}/></td>
    )

    if (canSupply) {
        let tanks = itemOrder.tanks;

        cells.push(
            <td className="hidden-print">
                <button className="btn btn-primary" onClick={() => onReceive(itemOrderId)}>Receive
                </button>
            </td>
        );
    }

    if (editable) {
        cells.push(
            <td>
                <a
                    href="javascript:void(0)"
                    title="Delete"
                    onClick={() => onDelete(itemOrderId)}
                >
                    <Trash/>
                </a>
            </td>
        );
    }

    return (
        <tr>
            {cells}
        </tr>
    );
};

interface IORFProps {
    itemOrder: ItemOrder;
    onSubmit: (formData: object) => void;
    delay?: number;
}

const ItemOrderRowForm: React.FC<IORFProps> = ({delay, itemOrder, onSubmit, children}) => {

    const data = {
        orderqty: itemOrder.orderqty,
        unitcost: itemOrder.unitcost,
        taxRateId: itemOrder.taxRateId
    }

    return (
        <Form onSubmit={onSubmit} initialValues={data}>

            {
                ({handleSubmit, submitting, values, errors, active}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            {delay !== undefined ?
                                <AutoSave values={values} submitting={submitting} submit={handleSubmit} delay={delay}/>
                                : null
                            }
                            {/*<AutoSave values={values} submitting={false} submit={handleSubmit} delay={1000}/>*/}
                            {children}
                        </form>
                    )
                }
            }
        </Form>
    )
}
