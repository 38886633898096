import NoResults from "../common/NoResults";
import ItemSearchResults from "../common/ItemSearchResults";
import {compose} from "recompose";
import withItemSearch from "../common/withItemSearch";
import connect from "../common/connect";
import PropTypes from "prop-types";
import React, {PureComponent} from "react";

class Items extends PureComponent {
    renderItems() {
        const {onSelect} = this.props;

        const {searchFetch} = this.props;

        if (searchFetch.rejected) {
            //TODO error handling
            return <div>Error</div>
        }

        if (searchFetch.pending) {
            return <div>loading</div>
        }

        // TODO cases: 1
        // TODO pending
        let items = searchFetch.value;

        console.log(searchFetch);


        let innerContent;
        if (items.length === 0) {
            innerContent = <div className="action-card"><NoResults/></div>
        } else {
            innerContent = items.map((item) => {
                return (
                    <div className="action-card" key={item.itemid}>
                        <a href="javascript:void(0)" onClick={() => {
                            onSelect(item);
                        }}>
                            <div>{item.description}</div>
                        </a>
                    </div>
                );
            });
        }

        const content =
            <div className="search-results-dropdown">
                <div className="action-card-container">
                    {innerContent}
                </div>
            </div>;

        if (searchFetch.refreshing) {
            return (
                <div className="loading" style={{width: '100%'}}>
                    <div className="loading-overlay" style={{width: '100%'}}>
                        {content}
                    </div>
                </div>
            )
        } else {
            return content
        }
    }

    renderSearchResults() {
        const {searchFetch, currentSearch} = this.props;

        if (searchFetch.pending || searchFetch.refreshing) {
            return null;
        }

        if (currentSearch) {
            return (
                // TODO search is wrong for this should only show the search that is currently being shown
                <ItemSearchResults search={currentSearch.value.search} isBarcode={currentSearch.value.isBarcode}/>
            )
        }
        return null;
    }

    render() {
        return (
            <div>
                {this.renderSearchResults()}
                {this.renderItems()}
            </div>
        );
    }
}

const ItemsWithFetch = compose(
    withItemSearch,
    connect((props) => {
        const {siteId, orderId, supplierId, search, isBarcode} = props;

        return {
            searchFetch: {
                url: `/supplier/${siteId}/item-search/${supplierId}?q=${encodeURIComponent(search)}`,
                comparison: search,
                refreshing: true,
                andThen: (value, meta) => {
                    console.log(value, meta);

                    return {
                        currentSearch: {
                            comparison: +(new Date()),
                            value: {
                                isBarcode,
                                search
                            }
                        }
                    }
                }
            },
        }
    }),
)(Items);

ItemsWithFetch.propTypes = {
    siteId: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired
};

export default ItemsWithFetch;
