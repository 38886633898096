import React from "react";
import {Provider, useDispatch, useSelector} from "react-redux";
import {duplicateOrder} from "./orderSlice";
import {RootState} from "../../app/rootReducer";
import store from "../../app/store";


// TODO combine with credit note button ?
// TODO loading state
interface DBProps {
    orderId: number;
}


const DuplicateButton = ({orderId}: DBProps) => {
    const dispatch = useDispatch();

    const {
        isDuplicateLoading
    } = useSelector((state: RootState) => state.order);

    const onPostDuplicate = async () => {
        dispatch(duplicateOrder(orderId));
    }

    return (

        <Provider store={store}>
            <button disabled={isDuplicateLoading} className="btn btn-secondary"
                    onClick={() => onPostDuplicate()}>Duplicate
            </button>
        </Provider>
    );
}

export default function ({orderId}: DBProps) {
    return (
        <Provider store={store}>
            <DuplicateButton orderId={orderId}/>
        </Provider>
    );
}
