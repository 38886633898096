import React, {Component} from "react";

export default class QuantityInput extends Component {
    constructor(props) {
        super(props);

        this.onKeyDown = this.onKeyDown.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.onChange = this.onChange.bind(this);
        this.increaseByOne = this.increaseByOne.bind(this);
        this.decreaseByOne = this.decreaseByOne.bind(this);
        this.handleBackspace = this.handleBackspace.bind(this);
    }

    handleBackspace() {
        this.props.changeQuantity(parseInt(this.props.qty / 10, 10));
    }

    onKeyDown(e) {
        const keyCode = e.keyCode;
        if (keyCode === 38) {
            e.preventDefault();
            this.increaseByOne();
        } else if (keyCode === 40) {
            e.preventDefault();
            this.decreaseByOne();
        }
    }

    onWheel(e) {
        e.preventDefault();
        if (e.deltaY > 1) {
            this.decreaseByOne();
        } else if (e.deltaY < -1) {
            this.increaseByOne();
        }
    }

    increaseByOne() {
        this.props.changeQuantity(this.props.qty + 1);
    }

    decreaseByOne() {
        this.props.changeQuantity(this.props.qty - 1);
    }

    onChange(e) {
        const val = e.target.value.replace(/\D/g, '');

        this.props.changeQuantity(val ? parseInt(val, 10) : 0);
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    <label className="control-label">Quantity</label>
                    <div className="input-group" style={{maxWidth: "150px"}}>
                    <span className="input-group-btn">
                    <button onClick={this.decreaseByOne}
                            className={"btn btn-danger" + (this.props.qty === 0 ? " disabled" : "")}
                            type="button">
                    <span className="glyphicon glyphicon-minus"/>
                    </button>
                    </span>
                        <input
                            onChange={this.onChange}
                            onKeyDown={this.onKeyDown}
                            onWheel={this.onWheel}
                            value={this.props.qty}
                            className="form-control text-center"
                        />
                        <span className="input-group-btn">
                    <button onClick={this.increaseByOne} className="btn btn-success" type="button">
                    <span className="glyphicon glyphicon-plus"/>
                    </button>
                    </span>
                    </div>
                </div>
            </div>
        );
    }
}
