import Form from "react-jsonschema-form";
import React, {PureComponent} from "react";

const uiSchema = {
    "itemid": {
        "ui:widget": "hidden"
    },
    "supplierorderid": {
        "ui:widget": "hidden"
    },

    "quantity" : {
        "ui:autofocus": true
    }
};

const schema = {
    type: "object",
    required: ["itemid", "supplierorderid", "orderqty", "unitcost"],
    properties: {
        itemid: {type: "number"},
        supplierorderid: {type: "number"},
        orderqty: {type: "number", title: "Quantity"},
        unitcost: {type: "number", title: "Unit Cost"}
    }
};

export default class ItemOrderForm extends PureComponent {
    render() {
        const {onSubmit, onCancel, formData} = this.props;

        return (
            <Form
                noHtml5Validate
                noValidate
                schema={schema}
                uiSchema={uiSchema}
                onSubmit={onSubmit}
                formData={formData}
            >


                <div className="clearfix">
                    <div className="btn-toolbar pull-right">
                        <button type="submit" className="btn btn-primary">Update</button>
                        <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                    </div>
                </div>
            </Form>
        );
    }
}
