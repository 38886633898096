import React, {Component} from "react";
import accounting from "accounting";
import Modal from "./Modal";
import ModalContent from "./ModalContent";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import Receipt from "./Receipt";
import PropTypes from "prop-types";
import Search from "./Search";
import fuzzy from "fuzzy";
import ModalHeader from "./ModalHeader";

export default class PastSales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            searchedPastSales: []
        };

        this.handleShowSale = this.handleShowSale.bind(this);
        this.handleCloseSale = this.handleCloseSale.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.handleSearch('');
    }

    handleSearch(term) {
        this.setState({
            searchTerm: term
        });

        term = term.trim();

        const extract = {
            extract: (sale) => sale.transactionNumber + '',
        };

        const items = fuzzy.filter(term, this.props.pastSales, extract).map((sale) => sale.original);

        this.setState({
            searchedPastSales: items
        });
    }

    handleShowSale(saleId) {
        this.setState({
            selectedSale: saleId,
        });

        this.props.onSelectSale(saleId);
    }

    handleCloseSale() {
        this.setState({
            selectedSale: undefined
        });

        this.props.onSelectSale();
    }

    getSelectedSale() {
        const pastSales = this.props.pastSales;

        for (let i = 0; i < pastSales.length; i++) {
            let pastSale = pastSales[i];
            if (pastSale.id === this.state.selectedSale) {
                return pastSale;
            }
        }
    }

    renderPastSaleDetail() {
        const selectedSale = this.getSelectedSale();

        if (selectedSale) {
            console.log(selectedSale);
            const payments = selectedSale.payments.map((payment) => {
                return (
                    <div>
                        {payment.description}
                        <div className="pull-right">{accounting.formatMoney(payment.amount)}</div>
                    </div>
                );
            });

            const items = selectedSale.cart.map((item) => {
                return (
                    <div>
                        <h5>{item.name}</h5>
                        Quantity: {item.qty}
                        <div className="pull-right">Total Price: {accounting.formatMoney(item.totalPrice)}</div>
                    </div>
                )
            });

            return (
                <div>
                    <Modal show={!!this.state.selectedSale} cancel={this.handleCloseSale}>
                        <ModalContent>
                            {/*<ModalHeader>Sale - {selectedSale.transactionNumber}</ModalHeader>*/}
                            <ModalBody>
                                {/*<div className="row">*/}
                                {/*<div className="col-md-7">*/}
                                {/*<div className="row">*/}
                                {/*<div className="col-sm-6">*/}
                                {/*<h5>Sale Total</h5>*/}
                                {/*<div>{accounting.formatMoney(selectedSale.saleTotal)}</div>*/}
                                {/*</div>*/}
                                {/*<div className="col-sm-6">*/}
                                {/*<h5>Total Paid</h5>*/}
                                {/*<div>{accounting.formatMoney(selectedSale.totalPaid)}</div>*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*<hr/>*/}
                                {/*<div>*/}
                                {/*<h4>Payments</h4>*/}
                                {/*{payments}*/}
                                {/*</div>*/}
                                {/*<hr/>*/}
                                {/*<div>*/}
                                {/*<h4>Items</h4>*/}
                                {/*{items}*/}
                                {/*</div>*/}
                                {/*</div>*/}
                                {/*<div className="col-md-5">*/}
                                <Receipt
                                    duplicate={true}
                                    transactionNumber={selectedSale.transactionNumber}
                                    siteName={this.props.siteName}
                                    address={this.props.address}
                                    taxNumber={this.props.taxNumber}
                                    cart={selectedSale.cart} payments={selectedSale.payments}
                                    taxTotal={selectedSale.taxTotal} time={selectedSale.time}
                                    totalPaid={selectedSale.totalPaid}
                                    saleTotal={selectedSale.saleTotal}
                                    change={selectedSale.change}
                                    rounding={selectedSale.rounding}
                                    locale={this.props.locale}
                                    receiptHeader={this.props.receiptHeader}
                                    receiptFooter={this.props.receiptFooter}
                                    cashier={this.props.cashier}
                                    cashierFirstName={selectedSale.cashierFirstName}
                                    cashierLastName={selectedSale.cashierLastName}
                                />
                                {/*</div>*/}
                                {/*</div>*/}
                            </ModalBody>
                            <ModalFooter>
                                <div className="clearfix">
                                    <div className="btn-toolbar pull-right">
                                        <button className="btn btn-default" onClick={this.handleCloseSale}>Close
                                        </button>
                                        <button className="btn btn-default" onClick={window.print}>Print</button>
                                    </div>
                                </div>
                            </ModalFooter>
                        </ModalContent>
                    </Modal>
                </div>
            );
        }
    }

    render() {
        if (!this.props.pastSales.length) {
            return <div>No Past Sales</div>
        }

        // shallow clone pastSales
        let pastSales = this.state.searchedPastSales.map((item) => item);
        pastSales.reverse();


        pastSales = pastSales.map((pastSale) => {
            const date = new Date(pastSale.time);

            const dateTime = date.toLocaleDateString(this.props.locale) + ' - ' + date.toLocaleTimeString(this.props.locale);

            return (
                <div key={pastSale.id}
                     className={pastSale.id === this.state.selectedSale ? "action-card active" : "action-card"}>
                    <a href="javascript:void(0)"
                       onClick={this.handleShowSale.bind(this, pastSale.id)}>

                        <div className="row">
                            <div className="col-xs-3">
                                {pastSale.transactionNumber}
                            </div>

                            <div className="col-xs-3">
                                <span className="pull-right">{accounting.formatMoney(pastSale.saleTotal)}</span>
                            </div>

                            <div className="col-xs-6">
                                <span className="pull-right">{dateTime}</span>
                            </div>
                        </div>

                    </a>
                </div>
            );
        });

        return (
            <div style={{height: '100%'}}>
                <Search onSearch={this.handleSearch} searchTerm={this.state.searchTerm}/>
                <div style={{padding: "0 10px 20px", fontWeight: 'bold', fontSize: '125%', maxHeight: '45px', overflow: 'hidden'}}>
                    <div className="row">
                        <div className="col-xs-3">Transaction No.</div>
                        <div className="col-xs-3">
                            <span className="pull-right">Sale Total</span>
                        </div>

                        <div className="col-xs-6">
                            <span className="pull-right">Date / Time</span>
                        </div>
                    </div>
                </div>
                <div className="action-card-container pos-card-control" style={{height: 'calc(100% - 94px)'}}>{pastSales}</div>
                {this.renderPastSaleDetail()}
            </div>
        );
    }
}

PastSales.propTypes = {
    taxNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    address: PropTypes.string,
    receiptHeader: PropTypes.string,
    receiptFooter: PropTypes.string,
    onSelectSale: PropTypes.func,
    locale: PropTypes.string
};
