import React, {PureComponent} from 'react';
import {compose, withState} from "recompose";
import withItemSearch from "../common/withItemSearch";
import ItemSearchResults from "../common/ItemSearchResults";
import connect from "../common/connect";
import NoResults from "../common/NoResults";
import ErrorFlash from "../common/ErrorFlash";
import ItemReceive from "../common/stock-receive/ItemReceive";

class ReceiveStock extends PureComponent {
    renderModal() {
        let content = null;

        if (this.props.selectedItem && this.props.selectedItem.value) {
            content = <ItemReceive
                item={this.props.selectedItem.value}
                onChange={() => this.props.setAdjustmentTime(+(new Date()))}
                itemName={this.props.selectedItem.value.description}
                onClose={this.props.deselectItem}
            />
        }

        return content;
    }

    renderItems() {
        const {setShowModal, onSelect} = this.props;

        const {searchFetch} = this.props;

        if (searchFetch.rejected) {
            return null;
        }

        // HOC
        if (searchFetch.pending) {
            return <div>loading</div>
        }

        let items = searchFetch.value;

        if (items.length === 0) {
            return <NoResults/>
        }

        return items.map((item) => {
            return (
                <div className="action-card" key={item.itemid}>
                    <a onClick={() => {
                        setShowModal(true);
                        onSelect(item);
                    }} href="javascript:void(0)">
                        <div>{item.description}</div>
                        {/*<div>{item.stockonhand ? item.stockonhand : 0}</div>*/}
                    </a>
                </div>
            );
        });
    }

    renderError() {
        if (this.props.error) {
            return <ErrorFlash>{this.props.error}</ErrorFlash>
        }
    }

    render() {
        const {searchFetch, isBarcode, search} = this.props;

        if (searchFetch.rejected) {
            return this.renderError();
        }

        // TODO search / adjustment error handling
        return (
            <div>
                <ItemSearchResults search={search} isBarcode={isBarcode}/>
                {this.renderModal()}

                <div className="search-results-dropdown">
                    <div className="action-card-container">
                        {this.renderItems()}
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(
    withItemSearch,
    withState('adjustmentTime', 'setAdjustmentTime', +(new Date())),
    withState('error', 'setError'),

    connect((props) => {
        const {siteId, search, adjustmentTime, isBarcode, setError} = props;

        return {
            searchFetch: {
                url: `/itemdrystock/${siteId}/search?q=${encodeURIComponent(search)}&outstandingOrders=1`,
                comparison: `${search}-${adjustmentTime}`,
                refreshing: true,
                catch: (reason) => {
                    setError("Error on search");
                },

                andThen: (value, meta) => {
                    return {
                        currentSearch: {
                            comparison: +(new Date()),
                            value: {
                                isBarcode,
                                search
                            }
                        }
                    }
                }
            },
        };
    }),

    withState('showModal', 'setShowModal', false),

    connect((props) => {
        return {
            deselectItem: () => {
                return {
                    selectedItem: {
                        value: null
                    }
                }
            },
            onSelect: (item) => {
                return {
                    selectedItem: {
                        comparison: item.itemid,
                        value: () => item
                    }
                }
            },
        };
    }),
)(ReceiveStock);
