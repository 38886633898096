import React, {Fragment, useState} from 'react';
import {Modal} from "react-bootstrap";
import {withModal} from "./StockReceiveWidget";
import ItemOrderSelector from "./ItemOrderSelector";
import connect from "../connect";
import ItemOrderReceive from "./ItemOrderReceive";
import PropTypes from 'prop-types';
import {compose} from "recompose";

export const ItemReceive = (props) => {
    const [itemOrderId, selectItemOrderId] = useState(undefined);

    const {outstandingOrdersFetch, itemName, closeModal} = props;

    if (itemOrderId) {
        return <ItemOrderReceive itemorderid={itemOrderId} onChange={props.onChange} closeModal={closeModal}/>
    }

    let content = null;

    if (outstandingOrdersFetch.pending) {
        content = <div>loading</div>
    } else if (outstandingOrdersFetch.rejeceted) {
        content = <div>error</div>
    } else {
        content =
            <ItemOrderSelector onSelect={({itemorderid}) => {
                selectItemOrderId(itemorderid)
                //    TODO outstanding orders should be loaded here
            }} itemOrders={outstandingOrdersFetch.value}/>
    }

    return <Fragment>
        <Modal.Header closeButton>
            <Modal.Title>{itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {content}
        </Modal.Body>
    </Fragment>
};

const ItemReceiveWithFetch = compose(
    withModal,
    connect((props) => {
            const {item, closeModal} = props;

            return {
                outstandingOrdersFetch: {
                    url: `/itemdrystock/outstanding-orders/${item.itemid}`
                }
            }
        },

    )
)(ItemReceive);

ItemReceiveWithFetch.propTypes = {
    itemName: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default ItemReceiveWithFetch;
