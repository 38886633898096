import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class MainControls extends Component {
    handleSelect(option) {
        this.props.onSelect(option);
    }

    render() {
        const options = this.props.options.map(item => {
            return <a key={item} href="#" onClick={this.handleSelect.bind(this, item)}
                      className={this.props.selected === item ? 'active' : ''}>{item}</a>
        });

        return (
            <div className="main-controls">
                <div className="pos-toolbar">
                    {options}
                </div>
            </div>
        );
    }
}

MainControls.propTypes = {
    locale: PropTypes.string,
    cartQuantity: PropTypes.number,
    selected: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    onSelect: PropTypes.func
}
