import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import axios from "../utils/axios";
import {Form} from "react-final-form";

interface CNBProps {
    onSubmit: () => void;
    orderId: number;
}

const postCreditNote = (orderId: number) => {
    return axios.post<{redirect: string}>(`/supplierorder/credit/${orderId}`);
}

export const CreditNoteButton = ({onSubmit, orderId}: CNBProps) => {
    const [show, setShow] = useState(false);



    const onPostCreditNote = async () => {
        setShow(false);

        await postCreditNote(orderId).then((e) => {
            window.location.href = e.data.redirect;
        });

        // TODO error handling outside of global?
    }

    return (
        <React.Fragment>
            <button className="btn btn-secondary" onClick={() => setShow(true)}>New Credit Note</button>

            <Modal show={show} onHide={() => setShow(false)} centered>
                 {/*@ts-ignore}*/}
                <Modal.Header closeButton>
                    <Modal.Title>Create Credit Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Create a credit note for this purchase order?
                    </p>

                    <p>
                        <small>This will reverse the purchase order</small>
                    </p>
                </Modal.Body>

                <Modal.Footer >
                    {/* TODO this component should handle the credit note request and close on success */}

                    <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>
                        Cancel
                    </button>

                    <Form
                        onSubmit={ onPostCreditNote}
                        render={({handleSubmit, submitting, pristine, invalid, values, errors}) => (

                            <form onSubmit={handleSubmit}>
                                <button type="submit" className="btn btn-primary">OK</button>
                            </form>
                        )}
                    />
                </Modal.Footer>
            </Modal>

        </React.Fragment>

    );
}
