import {Sale as SaleInterface, SaleItem} from "../../api/reconciliationAPI";
import React from "react";

const SaleItemDisplay = ({saleItem}: {saleItem: SaleItem})  => {
    return (
        <div>
            {saleItem.description} - {saleItem.quantity} - {saleItem.unitprice} - {saleItem.totalprice}
        </div>
    )
}

export const SaleDisplay = ({sale}: {sale: SaleInterface}) => {
    let rvmsite = sale.rvmsite;
    return (
        <div>
            <div>
                <div>
                    {rvmsite.sitename}
                </div>
                <div>
                    {rvmsite.physicaladdress}
                </div>
                <div>
                    {rvmsite.sitephone}
                </div>
                <div>
                    {/*TODO tax code name should be provided by the site model */}
                    GST#: {rvmsite.taxcode}
                </div>
            </div>


            <div>
                <div>
                    {sale.stationdescription}, Transaction {sale.transactionnumber}
                </div>
                <div>
                    Served By: {sale.cashierFullName}
                </div>
            </div>

            <div>
                date/times - to site time
            </div>

            <div>

            </div>


            {sale.saleitems.map((saleItem) => <SaleItemDisplay saleItem={saleItem}/>)}



            <div>
                <div>
                    Subtotal: {sale.saletotal}
                </div>
                <div>
                    Rounding: {sale.rounding}
                </div>

                <div>
                    Total: {sale.saletotal + sale.rounding}
                </div>
            </div>
        </div>
    )

}