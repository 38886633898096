import React, {PureComponent} from 'react';
import {compose, withState} from "recompose";
import connect from "../common/connect";
import {OrderForm} from "./OrderForm";

class Orders extends PureComponent {
    render() {
        const {
            itemOrderFetch,
        } = this.props;

        if (!itemOrderFetch.pending && !itemOrderFetch.rejected) {
            return (
                <OrderForm
                    {...this.props}
                    purchaseOrder={itemOrderFetch.value}
                    refreshing={itemOrderFetch.refreshing}
                />

            );
        }

        return null
    }
}

//TODO refactor item search / item list with other instances e.g stocktake, receivestock

export default compose(
    withState('showModal', 'setShowModal', false),

    connect((props) => {
        const {siteId, orderId, setShowModal} = props;

        const refreshOrder = (force = true) => {
            return {
                itemOrderFetch: {
                    comparison: siteId,
                    refreshing: true,
                    url: `/supplierorder/${siteId}/itemorders?id=${orderId}`,
                    force: force,
                    // then: ({requireReceivedDate}) => {
                    //     if (requireReceivedDate) {
                    //         // force the modal to be shown if the received date is required
                    //         setShowModal(true);
                    //     }
                    // }
                },
            }
        };

        return {
            refreshOrder: refreshOrder,
            onUpdateOrder: (formData) => {
                return {
                    updateOrderResponse: {
                        method: 'POST',
                        url: `/supplierorder/update/${orderId}`,
                        body: JSON.stringify(formData),
                        // TODO could replace value instead
                        andThen: () => {
                            setShowModal(false);
                            return refreshOrder();
                        }
                    }
                }
            },

            onUpdateItemOrder: (itemOrderId, formData) => {
                return {
                    updateOrderResponse: {
                        method: 'POST',
                        url: `/itemorder/update/${itemOrderId}`,
                        body: JSON.stringify(formData),
                        // TODO could replace value instead
                        andThen: () => {
                            setShowModal(false);
                            return refreshOrder();
                        }
                    }
                }
            },


            onCreditOrder: () => {
                return {
                    creditOrderResponse: {
                        method: 'POST',
                        url: `/supplierorder/credit/${orderId}`,
                        andThen: (value) => {
                            window.location = value.redirect;
                            return refreshOrder();
                        }
                    }
                }

            },

            onReceiveOrder: () => {
                return {
                    receiveOrderResponse: {
                        method: 'POST',
                        url: `/supplierorder/receive/${orderId}`,
                        andThen: refreshOrder
                    }
                }
            },

            onDeleteOrder: () => {
                return {
                    deleteOrderResponse: {
                        method: 'POST',
                        url: `/supplierorder/delete/${orderId}`,
                        andThen: ({redirect}) => {
                            // redirect on successful delete
                            window.location.replace(redirect);
                        }
                    }
                }
            },

            onCancelOrder: () => {
                return {
                    cancelOrderResponse: {
                        method: 'POST',
                        url: `/supplierorder/cancel/${orderId}`,
                        andThen: refreshOrder
                    }
                }
            },

            onPlaceOrder: () => {
                return {
                    placeOrderResponse: {
                        method: 'POST',
                        url: `/supplierorder/place-order/${orderId}`,
                        andThen: refreshOrder
                    }
                }
            },

            onDeleteItemOrder: (id) => {
                return {
                    deleteItemOrderResponse: {
                        method: 'DELETE',
                        url: `/itemorder/delete/${id}`,
                        andThen: refreshOrder
                    }
                }
            },

            onSubmitItemOrder: (formData) => {
                return {
                    itemOrderResponse: {
                        method: 'POST',
                        url: `/itemorder/create`,
                        body: JSON.stringify(formData),
                        andThen: () => {
                            setShowModal(false);
                            return refreshOrder();
                        }
                    }
                }
            },

            onClear: () => {
                return {
                    selectedItem: {
                        force: true,
                        value: null,
                    }
                }
            },

            onSelect: (item) => {
                return {
                    selectedItem: {
                        comparison: item.itemid,
                        // TODO formdata for item order form here
                        value: item
                    }
                }
            },

            itemOrderFetch: {
                comparison: siteId,
                url: `/supplierorder/${siteId}/itemorders?id=${orderId}`,
                then: ({requireReceivedDate}) => {
                    if (requireReceivedDate) {
                        // force the modal to be shown if the received date is required
                        setShowModal(true);
                    }
                }
            },
        }
    }),

    withState('selectedItemOrderId', 'setSelectedItemOrderId'),
)(Orders);
