import React from 'react';
import ReactDOM from 'react-dom';
import {Reconciliation} from "./Reconciliation";
import {Provider} from "react-redux";
import store from "../../app/store";

export default () => {
    const containers = document.getElementsByClassName('reconciliation');


    for (let i = 0; i < containers.length; i++) {

        const container = containers[i];
        const customerId = container.getAttribute('data-customer-id');
        const siteId = container.getAttribute('data-site-id');

        if (customerId !== null && siteId !== null) {
            ReactDOM.render(
                <Provider store={store}>
                    <Reconciliation customerId={parseInt(customerId)} siteId={parseInt(siteId)}/>
                </Provider>,
                container
            )
        }
    }
};
