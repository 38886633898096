import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {getPosList, POS} from "../../api/reportAPI";

interface ReportState {
    error: string | null
    isLoading: boolean
    pos: POS[]
}

let questionnaireInitialState: ReportState = {
    pos: [],
    // TODO should be false
    isLoading: true,
    error: null
};


function startLoading(state: ReportState) {
    state.isLoading = true
}

function loadingFailed(state: ReportState, action: PayloadAction<string>) {
    state.isLoading = false;
    state.error = action.payload
}

const reportSlice = createSlice({
    name: 'report',
    initialState: questionnaireInitialState,
    reducers: {
        getPosStart: startLoading,
        getPosSuccess(state, {payload}: PayloadAction<POS[]>) {
            state.pos = payload;
            state.isLoading = false;
            state.error = null;
        },

        getPosFailure: loadingFailed
    }
});

export const {
    getPosStart,
    getPosSuccess,
    getPosFailure,
} = reportSlice.actions;


export const fetchPosList = (
    siteId: number
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        dispatch(getPosStart());
        const questionnaires = await getPosList(siteId);
        dispatch(getPosSuccess(questionnaires));
    } catch (err) {
        dispatch(getPosFailure(err.toString()))
    }
};

export default reportSlice.reducer;
