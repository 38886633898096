import React from "react";
import {PurchaseOrder} from "./OrderForm";

import {Form} from "react-final-form";
import {Calculation} from "final-form-calculate";
import {DateTimeFormComponent} from "../common/DateTimeFormComponent";

interface ODProps {
    onSubmit: (values: object) => any;
    order: PurchaseOrder
}

interface DFProps {
    name: string;
    value?: string;
    onSubmit: (values: object) => any
}

const DateForm = ({name, value, onSubmit}: DFProps) => {

    const dateTimeName = `dateTime-${name}`
    // display date format for value and widget
    let dateFormat = 'DD MMM YYYY';

    let saveFormat = 'YYYY-MM-DD HH:mm:ss';


    // derive savable value from date time widget
    const calculations: Calculation[] = [{
        field: dateTimeName,
        updates: {
            // @ts-ignore
            [name]: (ignoredValue, allValues: AllValues) => {
                console.log(allValues[dateTimeName]);
            }
        }
    }]


    return (
        <Form initialValues={{[name]: value}} onSubmit={(values) => {
            onSubmit(values)
        }}>
            {({handleSubmit, values, submitting, valid, dirty}) => {
                console.log(values);

                return (
                    <form onSubmit={handleSubmit}>
                        {/* search box */}
                        <div className="form-group">
                            {/*<label htmlFor="search-input">Search</label>*/}

                            <DateTimeFormComponent submitButton dateFormat={dateFormat} name={name}/>
                        </div>
                    </form>
                )
            }}
        </Form>
    )
}
export const OrderDates = ({order, onSubmit}: ODProps) => {
    let requiredDate, orderDate, receivedDate = null;

    if (order.orderRequiredDate) {
        requiredDate = <div className="mr-2">
            <strong>Required Date</strong>&nbsp;
            <DateForm name='orderRequiredDate' value={order.orderRequiredDate} onSubmit={onSubmit}/>
        </div>;
    }

    if (order.orderOrderDate) {
        orderDate = <div className="mr-2">
            <strong>Order Date</strong>&nbsp;
            <DateForm name='orderOrderDate' value={order.orderOrderDate} onSubmit={onSubmit}/>
        </div>;
    }

    if (order.orderReceivedDate) {
        receivedDate = <div className="mr-2">
            <strong>Received Date</strong>&nbsp;
            <DateForm name='orderReceivedDate' value={order.orderReceivedDate} onSubmit={onSubmit}/>
        </div>;
    }

    return (
        <div className="d-flex flex-wrap mr-n1">
            {orderDate}
            {requiredDate}
            {receivedDate}
            {/*<div>{itemOrder.taxtypeid}</div>*/}
        </div>
    )
}