import Decimal from "decimal.js";
import React, {Component} from "react";
import accounting from "accounting";
import LocalDebtorCards from "./LocalDebtorCards.jsx";
import ModalForm from "./ModalForm";
import {Modal} from "react-bootstrap";

export default class MakePayment extends Component {
    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onQuickButtonClick = this.onQuickButtonClick.bind(this);
        this.handleCustomerPayment = this.handleCustomerPayment.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    onSubmit() {
        this.props.onPayFinal(accounting.unformat(this.props.amount), this.props.method);
    }

    onBlur() {
        this.props.onCashChange(accounting.formatMoney(this.props.amount));
    }

    onChange(e) {
        this.props.onCashChange(e.target.value);
    }

    onQuickButtonClick(amount) {
        this.props.onPayFinal(amount, this.props.method);
    }

    nearestMultiples(value) {
        const seen = {};
        const cash = [1, 2, 5, 10, 20, 50, 100].map((cash) => new Decimal(cash));

        return cash
            .map((face) => Math.ceil(value / face) * face)
            .filter((item) => seen.hasOwnProperty(item) ? false : (seen[item] = true));
    }

    onClear() {
        this.props.onCashChange(accounting.formatMoney(0));
    }

    handleCustomerPayment(customer) {
        console.log(this.props.method);
        this.props.onPayFinal(this.props.amountDue, this.props.method, customer);
    }

    render() {
        const amountDue = this.props.amountDue,
            method = this.props.method;

        let cashButtons;
        if (method.moptypeid === "MOPTYPE_CASH") {
            const numButtons = 5,
                nearestFactors = this.nearestMultiples(amountDue).slice(0, numButtons),
                quickButtonsStyle = {width: (100 / nearestFactors.length).toFixed(5) + '%'};

            const quickButtons = nearestFactors.map((amount, index) => {
                return (
                    <div key={index} className="cash-btn-wrap" style={quickButtonsStyle}>
                        <button onClick={this.onQuickButtonClick.bind(this, amount)} type="button"
                                className="btn btn-default btn-block">
                            {accounting.formatMoney(amount)}
                        </button>
                    </div>
                );
            });

            cashButtons =
                <div className="form-group">
                    <div className="cash-btn-container clearfix">
                        {quickButtons}
                    </div>
                </div>;
        } else if (method.moptypeid === "MOPTYPE_LOCAL_DEBTOR") {
            return (
                <Modal show={true} onHide={this.props.onCancel}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Select Local Debtor
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <LocalDebtorCards
                            contactToLocalDebtor={this.props.contactToLocalDebtor}
                            localDebtorCards={this.props.localDebtorCards}
                            onLocalDebtorSelect={this.handleCustomerPayment}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-default" onClick={this.props.onCancel}>Cancel</button>
                    </Modal.Footer>
                </Modal>
            );
        }

        return (
            <ModalForm
                title={method.description}
                onSubmit={this.onSubmit}
                submitText="Pay"
                onCancel={this.props.onCancel}
            >
                <div className="form-group">
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            onChange={this.onChange}
                            onBlur={this.onBlur}
                            value={this.props.amount}
                        />
                        <span className="input-group-btn">
                    <button className="btn btn-default" onClick={this.onClear} type="button">Clear</button>
                    </span>
                    </div>
                </div>
                {cashButtons}
                <h4>Amount due: <strong>{accounting.formatMoney(amountDue)}</strong></h4>
            </ModalForm>
        );
    }
}
