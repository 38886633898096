// api-connector.js
import { connect } from 'react-refetch'
import getCsrfToken from "./csrfToken";

export default connect.defaults({
    buildRequest: (mapping) => {
        const options = {
            method: mapping.method,
            headers: mapping.headers,
            credentials: 'include',
            redirect: mapping.redirect,
            mode: mapping.mode,
            body: mapping.body
        };

        // add CSRF header
        options.headers['X-CSRF-Token'] = getCsrfToken();

        return new Request(mapping.url, options)
    },
});
