import React, {Component} from "react";
import QuestionInput from "./QuestionInput.jsx";
import QuantityInput from "./QuantityInput.jsx";
import ModalForm from "./ModalForm";
import MoneyInput from "./MoneyInput";
import accounting from "accounting";
import update from "immutability-helper";

export default class ItemControls extends Component {
    constructor(props) {
        super(props);

        this.changeQuantity = this.changeQuantity.bind(this);
        this.handleAnswerChange = this.handleAnswerChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleUnitPriceOverrideChange = this.handleUnitPriceOverrideChange.bind(this);
    }

    componentWillMount() {
        this.prepareComponentState(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.prepareComponentState(nextProps);
    }

    prepareComponentState(props) {
        // props used to set initial state - intentional
        // underlying item state only changed on save
        const item = props.item;

        // if (item.product.questions) {
        //     let answers;
        //     if (item.answers) {
        //         answers = item.answers
        //     } else {
        //         answers = [];
        //         for (var i = 0; i < item.product.questions.length; i++) {
        //             answers[i] = {text: '', error: false};
        //         }
        //     }
        //
        //     this.setState({answers: answers});
        // }

        this.setState({
            qty: item.qty,
        });

        const unitPriceOverride = item.unitPriceOverride;
        if (unitPriceOverride) {
            this.setState({
                unitPriceOverride: accounting.formatMoney(unitPriceOverride)
            });
        }
    }

    validateAnswer(index, text) {
        return this.props.item.product.questions[index].required ? !!text : true;
    }

    changeQuantity(qty) {
        if (qty <= 0) {
            qty = 0;
        } else if (qty >= 9999) {
            qty = 9999;
        }

        this.setState({
            qty: qty
        });

        // this.props.onQuantiyChange(this.props.index, qty);
    }

    handleUnitPriceOverrideChange(amount) {
        this.setState({
            unitPriceOverride: amount
        });

        // this.checkValidity(newAmount)
    }

    handleAnswerChange(index, text) {
        // add validate on change?
        const update = {};
        update[index] = {text: {$set: text}, error: {$set: !this.validateAnswer(index, text)}};
        this.setState({answers: update(this.state.answers, update)});
    }

    validate() {
        let update = {}, answer, errors = 0, isValid;
        for (let i = 0; i < this.state.answers.length; i++) {
            answer = this.state.answers[i];
            isValid = this.validateAnswer(i, answer.text);
            errors += isValid ? 0 : 1;
            update[i] = {error: {$set: !isValid}};
        }
        this.setState({answers: update(this.state.answers, update)});
        return errors;
    }

    handleSubmit() {
        if (!this.state.answers || this.validate() === 0) {
            // TODO validation
            const unitPriceOverride = accounting.parse(this.state.unitPriceOverride);
            this.props.onQuantiyChange(this.props.index, this.state.qty, unitPriceOverride);
            // this.props.onSave(this.props.index, this.state.qty, this.state.answers, this.props.item.product);
        }
    }

    renderUnitPriceOverride() {
        if (this.props.item.unitPriceOverride) {
            return (
                <div className="form-group">
                    <label className="control-label">Price</label>
                    <MoneyInput onChange={this.handleUnitPriceOverrideChange} value={this.state.unitPriceOverride} />
                </div>
            );
        }
    }

    renderQuestionInputs() {
        if (this.props.item.product.questions) {
            return this.props.item.product.questions.map((question, index) => {
                return (
                    <QuestionInput
                        onAnswerChange={this.handleAnswerChange}
                        key={index}
                        id={index}
                        question={question}
                        answer={this.state.answers[index]}
                    />
                );
            });
        }
    }

    render() {
        return (
            <ModalForm
                onSubmit={this.handleSubmit}
                title={this.props.item.product.name}
                submitText={this.props.newRecord ? "Add" : "Save"}
                onCancel={this.props.onCancel}
            >
                {this.renderUnitPriceOverride()}
                <QuantityInput changeQuantity={this.changeQuantity} qty={this.state.qty}/>
                {this.renderQuestionInputs()}
                <button className="btn btn-danger" onClick={this.props.onDeleteItem}>
                    Delete
                </button>
            </ModalForm>
        );
    }
}
