import React, {useEffect, useState} from "react";
import {Field} from "react-final-form";
import diff from 'object-diff'

const FieldPrefixContext = React.createContext("");

interface FPProps {
    prefix: string
}

export const FieldPrefix: React.FunctionComponent<FPProps> = ({prefix, children}) => (
    <FieldPrefixContext.Provider value={prefix}>
        {children}
    </FieldPrefixContext.Provider>
);

interface PFProps {
    name: string
}

export const PrefixedField: React.FunctionComponent<PFProps> = ({name, ...props}) => (
    <FieldPrefixContext.Consumer>
        {prefix => <Field name={prefix.length === 0 ? name : `${prefix}.${name}`} {...props} />}
    </FieldPrefixContext.Consumer>
);

interface BaseProps {
    delay: number
}

interface InjectedProps {
    values: object,
    submitting: boolean,
    submit:  () => any | undefined
}

let timeout: undefined | NodeJS.Timeout;

export const AutoSave = ({values, submitting, delay, submit}: InjectedProps & BaseProps) => {
    const [oldValues, setValues] = useState(values);

    useEffect(() => {
        if (timeout) {
            clearTimeout(timeout)
        }

        timeout = setTimeout(() => {
            const difference = diff(oldValues, values);

            let changed = Object.keys(difference);

            console.log(oldValues, values, changed)

            // also check that the length of oldValues and values are the same as diff only checks difference between properties that exist
            const hasChanged = changed || Object.keys(oldValues).length !== Object.keys(values).length;

            console.log(hasChanged);

            if (hasChanged && !submitting) {
                // values have changed and not currently submitting
                setValues(values);
                submit();
            }
        }, delay)
    }, [values]);

    return null;
};