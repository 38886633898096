import React, {Component} from 'react';
import Search from "../pos/Search";
import barcodeScanning from "../helpers/barcodeScanning";


/*
 * Provides barcode scanning and searching for shop items
 */
const withItemSearch = (WrappedComponent) => (
    class ItemSearch extends Component {
        state = {
            searchTerm: '',
            currentSearchTerm: ''
        };

        constructor(props) {
            super(props);

            barcodeScanning((barcode) => {
                this.setState({barcode: barcode});
            });
        }

        clearBarcode() {
            this.setState({
                barcode: undefined
            });
        }

        onSearch(q) {
            // TODO pull some of this out into state handlers
            const {searchTimeout} = this.props;

            this.setState({searchTerm: q});

            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }

            this.searchTimeout = setTimeout(() => {
                this.clearBarcode();
                this.setState({currentSearchTerm: q});
            }, searchTimeout ? searchTimeout : 150)
        }

        render() {
            // TODO search / adjustment error handling
            const {searchTerm, currentSearchTerm, barcode} = this.state;
            const {searchTimeout, ...rest} = this.props;

            let toSearch;
            const isBarcode = !!barcode;
            if (isBarcode) {
                toSearch = barcode;
            } else {
                toSearch = currentSearchTerm;
            }

            return (
                <div style={{height: "100%"}}>
                    <Search
                        onSearch={(q) => this.onSearch(q)} searchTerm={isBarcode ? '' : searchTerm}
                    />
                    <WrappedComponent search={toSearch} isBarcode={isBarcode} {...rest}/>
                </div>
            );
        }
    }
);

export default withItemSearch;
