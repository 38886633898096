import React from 'react';
import ReactDOM from 'react-dom';
import {Stocktake} from "./Stocktake";

export default () => {
    const container = document.getElementById('stocktake');

    if (container) {
        const siteId = container.getAttribute('data-site-id');

        if (siteId !== null) {
            ReactDOM.render(
                <Stocktake siteId={parseInt(siteId)}/>,
                container
            )
        }
    }
};
