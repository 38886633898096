/**
 * Created by RDTS on 31/03/2017.
 */

const generateUUID = function () {
    let d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
        d += window.performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

export default generateUUID;
