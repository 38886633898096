import React, {Component} from 'react';

export default class QuestionInput extends Component {
    constructor(props) {
        super(props);
        this.onTextChange = this.onTextChange.bind(this);
    }

    onTextChange(e) {
        this.props.onAnswerChange(this.props.id, e.target.value);
    }

    render() {
        const answer = this.props.answer;
        const question = this.props.question;
        let className = "form-group";

        className += question.required ? " required" : " optional";

        if (answer.error) {
            className += " has-error";
        }

        return (
            <div className={className}>
                <label className="control-label">{this.props.question.text}</label>
                <input
                    type="text"
                    className="form-control"
                    value={answer.text}
                    onChange={this.onTextChange}
                    onBlur={this.onTextChange}
                />
            </div>
        );
    }
}
