import React, {Component} from 'react';
import PropTypes from 'prop-types';
// import './modal.css';

export default class Modal extends Component {
    constructor(props) {
        super(props);

        window.addEventListener("keydown", (e) => {
            if (e.key === 'Escape' || e.keyCode === 27) {
                this.props.cancel && this.props.cancel();
            }
        });
    }

    render() {
        return (
            <div onKeyDown={this.handleKeyDown} tabIndex="-1" className={this.props.show ? "pos-modal active" : "pos-modal" }>
                {this.props.children}
            </div>
        );
    }
}

Modal.propTypes = {
    show: PropTypes.bool,
    cancel: PropTypes.func
};
