import {configureStore, Action} from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'
import rootReducer, { RootState } from './rootReducer'
import {reconciliationApi} from "../services/reconciliation";
import {setupListeners} from "@reduxjs/toolkit/query";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (
        getDefaultMiddleware().concat(reconciliationApi.middleware)
    )
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export default store;
