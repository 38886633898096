/**
 * Created by RDTS on 31/05/2018.
 */
// provides form widget that connects with api

import React from "react";
import {compose} from "recompose";
import connect from './connect';
import PropTypes from 'prop-types';
import Form from "./Form";

// TODO add on save success / save indication
// TODO  should use prop update to determine method?
// TODO refactor to one for update and one for new?
const FormConnect = compose(
    connect((props) => {
        const {id, url, onSuccess, model} = props;

        let method = id ? 'PUT' : 'POST';

        const fetches = {
            postModel: (model) => {
                return {
                    modelFetch: {
                        url: url,
                        method: method,
                        body: JSON.stringify(model),
                        then: (model) => {
                            if (typeof onSuccess === "function") {
                                onSuccess(model);
                            }
                        },
                        refreshing: true,
                    },
                    modelStatic: {
                        value: model
                    }
                }
            },
            modelStatic: {
                value: null
            },
        };

        if (model) {
            fetches.modelFetch = {
                value: model
            }
        } else if (id) {
            fetches.modelFetch = {
                url: url,
                comparison: id
            }
        } else {
            fetches.modelFetch = {
                value: {}
            }
        }

        return fetches;
    }),
)((props) => {
    const modelFetch = props.modelFetch;

    let model = modelFetch.value, errors = [];

    if (modelFetch.pending) {
        return <div>
            loading
        </div>
    }

    if (modelFetch.rejected) {
        if (modelFetch.meta.response.status === 422) {
            model = props.modelStatic.value;
            errors = modelFetch.reason.cause;

            console.log(errors);

        } else {
            // TODO more useful error message or callback just displaying error not useful
            return <div>error</div>
        }
    }

    if (modelFetch.refreshing) {
        // when modelFetch is refreshing use the submitted value for model
        // this prevents model from going back to the original state on submission
        model = props.modelStatic.value;
    }

    return (
        <Form
            formData={model}
            schema={props.schema}
            uiSchema={props.uiSchema}
            onSubmit={({formData}) => {
                if (!(modelFetch.pending || modelFetch.refreshing)) {
                    props.postModel(formData);
                }
            }}
            errors={errors}
        />
    );
});

FormConnect.props = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    uiSchema: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired,
    model: PropTypes.object,
    onSuccess: PropTypes.func
};

export default FormConnect
