import React, {Component} from 'react';

export default class PosMobileProductButton extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onAddItem(this.props.id);
    }

    render() {
        let className;
        if (this.props.required) {
            className = 'required';
        }
        return (
            <li>
                <a href="#" className={className} onClick={this.handleClick}>
                    {this.props.children}
                </a>
            </li>
        );
    }
}
