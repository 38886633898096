import React, {Component} from "react";
import accounting from "accounting";
import ModalForm from "./ModalForm";
import ErrorBlock from "./ErrorBlock";
import PropTypes from "prop-types";

export default class PriceOverride extends Component {
    constructor(props) {
        super(props);

        this.state = {
            amount: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    checkValidity(newAmount) {
        const amount = accounting.parse(newAmount ? newAmount : this.state.float);

        let error;
        const maxAmount = 999999;
        if (amount > maxAmount) {
            const formattedMaxAmount = accounting.formatMoney(maxAmount);
            error = `Price must not be greater than ${formattedMaxAmount}.`;
        } else if (amount < 0) {
            error = "Price must be non-negative.";
        } else if (amount === 0) {
            error = "Price must not be zero."
        }

        this.setState({
            error: error
        });

        // if the error string has been set the form is invalid
        return !error;
    }

    handleBlur() {
        this.setState({
            amount: accounting.formatMoney(this.state.amount)
        });
    }

    handleSubmit() {
        const formattedAmount = accounting.formatMoney(this.state.amount);
        const numberAmount = accounting.toFixed(formattedAmount, 2);
        // TODO 0 check?

        if (this.checkValidity(this.state.amount)) {
            this.props.onAddItem(this.props.productId, 1, numberAmount);
        }
    }

    handleChange(e) {
        const newAmount = e.target.value;
        this.setState({
            amount: newAmount
        });

        this.checkValidity(newAmount)
    }

    render() {
        let error;
        if (this.state.error) {
            error = <ErrorBlock>{this.state.error}</ErrorBlock>;
        }

        return (
            <ModalForm
                onSubmit={this.handleSubmit}
                title={"Price for: " + this.props.productName}
                submitText="Add Item"
                onCancel={this.props.onCancel}
            >
                <div className="form-group">
                    <label className="control-label">Price</label>
                    <input
                        type="text"
                        className="form-control"
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.state.amount}
                    />
                </div>
                {error}
            </ModalForm>
        );
    }
}

PriceOverride.propTypes = {
    productId: PropTypes.number,
    productName: PropTypes.string,
    onAddItem: PropTypes.func
};
