import React, {Component} from "react";
import events from "../db/events";
import MoneyInput from "./MoneyInput";
import accounting from "accounting";
import ModalForm from "./ModalForm";
import ErrorBlock from "./ErrorBlock";
import PropTypes from 'prop-types';

export default class ShiftForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            float: accounting.formatMoney(0)
        };

        this.handleAddShift = this.handleAddShift.bind(this);
        this.handleFloatChange = this.handleFloatChange.bind(this);
    }

    checkValidity(newAmount) {
        const amount = accounting.parse(newAmount ? newAmount : this.state.float);

        let error;
        if (amount > 999999) {
            error = "Float must not be greater than $999,999.00";
        } else if (amount < 0) {
            error = "Float must be non-negative";
        }

        this.setState({
            error: error
        });

        // if the error string has been set the form is invalid
        return !error;
    }

    handleAddShift() {
        // TODO add exception

        if (this.checkValidity()) {
            events.activeShift().then(active => {
                if (!active) {
                    events.startShift(accounting.parse(this.state.float), this.props.cashierId, this.props.siteId);
                    this.props.onStart();
                }
            });
        }
    }

    handleFloatChange(float) {
        this.checkValidity(float);

        this.setState({
            float: float
        });
    }

    render() {
        let error;
        if (this.state.error) {
            error = <ErrorBlock>{this.state.error}</ErrorBlock>;
        }

        return (
            <ModalForm title="New Shift" onSubmit={this.handleAddShift} onCancel={this.props.onCloseModal}>
                <div className="form-group">
                    <label className="control-label" htmlFor="float">Till Float</label>
                    <MoneyInput
                        id="float"
                        ref={(input) => {
                            this.moneyInput = input
                        }}
                        onChange={this.handleFloatChange}
                        value={this.state.float}
                    />
                </div>
                {error}
            </ModalForm>
        );
    }
}

ShiftForm.propTypes = {
    cashierId: PropTypes.number,
    siteId: PropTypes.number,
    onStart: PropTypes.func,
    onCloseModal: PropTypes.func
};
