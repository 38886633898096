import React from 'react';
import ReactDOM from 'react-dom';
import ReceiveStock from "./ReceiveStock";

export default () => {
    const container = document.getElementById('receive-stock');

    if (container) {
        const siteId = container.getAttribute('data-site-id');

        ReactDOM.render(
            <ReceiveStock siteId={siteId}/>,
            container
        )
    }
};
