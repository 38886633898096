import React, {PureComponent} from 'react';
import PropTypes from "prop-types"

export default class ItemOrderSelector extends PureComponent {
    render() {
        return (
            <React.Fragment>
                {this.props.itemOrders.map((itemOrder) => {
                    return (
                        <p>
                            <strong>Supplier</strong><br/>
                            {itemOrder.organisationName}<br/>
                            <strong>Order Date</strong><br/>
                            {itemOrder.orderDate}<br/>
                            <strong>Quantity</strong><br/>
                            {itemOrder.unsuppliedQuantity}<br/>
                            <button className="btn btn-primary" onClick={() => this.props.onSelect(itemOrder)}>Receive</button>
                        </p>
                    )
                })}
            </React.Fragment>
        );
    }
}

ItemOrderSelector.propTypes = {
    itemOrders: PropTypes.arrayOf(PropTypes.shape({})),
    onSelect: PropTypes.func.isRequired
};
