import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/rootReducer";
import {fetchPosList} from "./reportSlice";

export const Report = () => {
    const dispatch = useDispatch();

    const {
        isLoading,
        pos
    } = useSelector((state: RootState) => state.report);


    useEffect(() => {
        // load initial section state
        dispatch(fetchPosList(1));
    }, [dispatch]);


    console.log(pos);

    return (
        <div>
            <form id="shiftreport-form" action="/report/1/shift"
                  method="GET">
                <div className="action-row clearfix">
                    <div className="text-center">
                        <h2>Shift Report</h2>
                    </div>

                    <div id="pos-list" className="action-col field-report-posstationid">
                        <label className="control-label" htmlFor="report-posstationid">Till</label>
                        <select id="report-posstationid" className="form-control" name="Report[posstationid]">
                            <option value="1">POS 1</option>
                            <option value="2">POS 2</option>
                        </select>

                    </div>
                    <div className="action-col field-report-posshiftid">
                        <label className="control-label" htmlFor="report-posshiftid">Shift</label>
                        <select id="report-posshiftid" className="form-control" name="Report[posshiftid]"
                                data-from-target="pos-list" data-url="/report/1/getshiftoptions">
                            <option value="1">4 Nov 2018 16:52</option>
                        </select>

                    </div>
                    <div className="action-col">
                        <div className="btn-toolbar">
                            <div className="btn-group">
                                <button type="submit" className="btn btn-default" name="export" value="pdf">PDF
                                </button>
                            </div>
                            <button type="submit" id="report-submit-button" className="btn btn-primary pull-right"
                                    name="export" style={{"marginLeft": 15}}>Show
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};
