import React, {PureComponent} from 'react';
import {compose, withState} from "recompose";
import connect from "../common/connect";

class PaymentAllocation extends PureComponent {
       render() {

        // TODO search / adjustment error handling
        return (
            <div>
                Payment Allocation
            </div>
        );
    }
}

export default compose(
    connect((props) => {
        return {
        };
    }),
)(PaymentAllocation);
