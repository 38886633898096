import axios from "../utils/axios";

export async function createRoa(customerNumber: number, siteId: number) {
    // drop existing order if itemOrderId set - this could also potentially be determined in the action instead
    return axios.post(`/reconciliation/${siteId}/customer-roa/${customerNumber}`, {
        mopId: 1,
        contactId: customerNumber,
        siteId: siteId,
        amount: 10000
    });
}

export interface SaleMop {

}

export interface SaleItem {
    quantity: number;
    description: string;
    taxratepercent: number;
    taxratedescription: string;
    totalprice: number;
    unitprice: string;
}

export interface Site {
    rvmsiteid: number;
    sitename: string;
    physicaladdress: string;
    sitephone: string;
    taxcode: string;
}

export interface Sale {
    saleid: number;
    stationname: string;
    stationdescription: string;
    taxtotal: number;
    transactionnumber: string;
    saletotal: string;
    datetime: string;
    change: string;
    rounding: number;
    cashierFullName: string;
    salemops: SaleMop[];
    saleitems: SaleItem[];
    rvmsite: Site;
}

export interface CustomerInvoice {
    sale: Sale;
    saleid: number;
    salemopid: number;
    due: number;
    paid: number;
    tender: number;
}

export interface UnallocatedROA {
    allocated: number;
    amount: number;
    saleid: number;
    saleitemid: number;
    unallocated: number;
}

export interface ReconciliationData {
    outstandingDebtorMops: CustomerInvoice[];
    unallocatedRoas: UnallocatedROA[];
}
