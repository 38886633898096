import './scss/site.scss';

import renderPos from "./pos/renderPos";
import renderReceiveStock from "./receive-stock/renderReceiveStock";
import renderPaymentAllocation from "./payment-allocation/renderPaymentAllocation";
import renderOrders from "./orders/renderOrders";
import renderOrderWetstock from "./order-wetstock/renderOrderWetstock";
import renderReport from "./features/report/renderReport";
import renderStocktake from "./features/stocktake/renderStocktake";
import renderReconciliation from "./features/reconciliation/renderReconciliation";
const hljs = require('highlight.js');

hljs.highlightAll();

// TODO code splitting
renderStocktake();
renderPos();
renderReceiveStock();
renderPaymentAllocation();
renderOrders();
renderOrderWetstock();
renderReport();
renderReconciliation();
// TODO complete migration to typescript