import {createSlice} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {postDuplicate} from "../../api/orderAPI";

interface OrderState {
    isDuplicateLoading: boolean
}

let orderInitialState: OrderState = {
    isDuplicateLoading: false,
};

const orderSlice = createSlice({
    name: 'order',
    initialState: orderInitialState,
    reducers: {
        postDuplicateStart(state) {
            state.isDuplicateLoading = true
        },

        postDuplicateSuccess(state) {

            state.isDuplicateLoading = false;
        }

    }
});

export const {
    postDuplicateStart,
    postDuplicateSuccess,
} = orderSlice.actions;

export const duplicateOrder = (
    orderId: number
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        dispatch(postDuplicateStart());
        const response = await postDuplicate(orderId);
        window.location.href = response.data.redirect;
    } catch (err) {
        dispatch(postDuplicateSuccess());

        // dispatch(postDuplicateFailure(err.toString()))
    }
};

export default orderSlice.reducer;
