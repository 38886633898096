import React, {Component} from "react";
import {Search as SearchIcon, X} from 'react-feather';


export default class Search extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    handleChange(e) {
        e.preventDefault();
        this.props.onSearch(e.target.value);
    }

    handleKeyDown(e) {
        console.log(e.key, e.keyCode);

        if (e.key === 'Escape' || e.keyCode === 27) {
            console.log('Escape');
            this.props.onSearch('');
        }
    }

    handleClear() {
        console.log('Clear');
        this.props.onSearch('');
    }

    render() {
        let clear;
        // TODO put styling in css - more padding for input? - size of icons should perhaps be calculated works with default height of bootstrap input only
        if (this.props.searchTerm) {
            clear = <span style={{padding: '0.375rem', position: 'absolute', right: 0}} onClick={this.handleClear}><X/></span>
        }

        const input = <input
            ref={(input) => {
                this.input = input
            }}
            type="text"
            className="form-control"
            placeholder="Search..."
            value={this.props.searchTerm}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
        />;


        return (
            <div className="search-wrap">
                <span style={{padding: '0.375rem', position: 'absolute'}}><SearchIcon/></span>
                {input}
                {clear}
            </div>
        );
    }
}
