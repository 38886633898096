import React, {FunctionComponent, useState} from 'react';
import {compose, withState} from "recompose";
import connect from "../common/connect";
import {Modal} from "react-bootstrap";
import {WetstockFormNewOrder} from "./WetstockForm";
import TankDrop from "./TankDrop";
import {TankDipModal} from "./TankDipModal";
import {TankDisplay} from "./TankDisplay";
import {PromiseState} from "react-refetch";
import {LoaderOverlay} from "../common/LoaderOverlay";

interface WetstockModalProps {
    show: boolean,
    onClose: () => any
    children: React.ReactNode
    tank: Tank
}

export const WetstockModal = (props: WetstockModalProps) => {
    const {show, onClose, tank, children} = props;

    return (
        <Modal show={show} onHide={onClose}>
            {/* @ts-ignore */}
            <Modal.Header closeButton>
                <Modal.Title>Fuel Drop <small>{tank.description}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    );
}

interface TankDipActionProps {
    tank: Tank;
    storageTankId: number;
    onSuccess: () => void;
}

const TankDipAction = ({storageTankId, tank, onSuccess}: TankDipActionProps) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <React.Fragment>
            <button className="btn btn-primary mr-2" onClick={() => setShowModal(true)}>Tank Dip</button>
            <TankDipModal
                tankName={tank.description}
                storageTankId={tank.storagetankid}
                show={showModal}
                onClose={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                onSubmit={() => {
                    setShowModal(false);
                    onSuccess()
                }}
            />
        </React.Fragment>
    );
}

interface FuelDrop {
    dateSupplied: string;
    volume: number;
    unitCost: number;
    supplierName: string;
}

interface TankReading {
    volume: number;
    watercontent: number;
    datetimeread: string;
    localDateTime: string;
    manualdip: boolean;
}

export interface Supplier {
    contactid: number;
    organisationname: string;
}

export interface Tank {
    name: string;
    //TODO load separately
    lastFiveTankReadings: TankReading[]
    lastFiveDrops: FuelDrop[]
    gradeName: string;
    description: string;
    abbreviateddescription: string;
    canPostAdjustment: boolean;
    calculatedSoh: number;
    latestTankReading: TankReading
    capacity: number;
    storagetankid: number;
    volume: number;
    variance: number;
    dropdownSupplierOptions: Supplier[]
}

interface OrderWetstockProps {
    setShowModal: (show: number | false) => void;
    showModal: number | false;
    tanksFetch: PromiseState<Tank>;
    postAdjustment: () => any;
    refreshTanks: () => any;
}

interface TRProps {
    tankReadings: TankReading[]
}


// TODO make paginated loader
const TankReadingDisplay = ({tankReadings}: TRProps) => {
    if (tankReadings.length === 0) {
        return <p>
            No tank readings
        </p>
    } else {
        return (
            <div>
                <p>
                    Last Five Tank Readings
                </p>
                <table className="table table-sm table-borderless" style={{margin: "0 -0.3em"}}>
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Volume</th>
                        <th scope="col">Water Content</th>
                        <th scope="col">Type</th>
                    </tr>
                    </thead>
                    <tbody>

                    {tankReadings.map((tankReading) => {
                        return (
                            <tr>
                                <td>
                                    {tankReading.localDateTime}
                                </td>

                                <td>
                                    {tankReading.volume}
                                </td>

                                <td>
                                    {tankReading.watercontent}
                                </td>


                                <td>
                                    {tankReading.manualdip ? "Manual" : "Automatic"}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
};

interface FDProps {
    fuelDrops: FuelDrop[]
}

// TODO make paginated loader
const FuelDropDisplay = ({fuelDrops}: FDProps) => {
    if (fuelDrops.length === 0) {
        return <p>
            No fuel drops
        </p>
    } else {
        return (
            <div>
                <p>
                    Last Five Fuel Drops
                </p>
                <table className="table table-sm table-borderless" style={{margin: "0 -0.3em"}}>
                    <thead>
                    <tr>
                        <th scope="col">Supplier</th>
                        <th scope="col">Date Supplied</th>
                        <th scope="col">Volume</th>
                        <th scope="col">Unit Cost</th>
                    </tr>
                    </thead>
                    <tbody>

                    {fuelDrops.map((fuelDrop) => {
                        return (
                            <tr>
                                <td>
                                    {fuelDrop.supplierName}
                                </td>

                                <td>
                                    {fuelDrop.dateSupplied}
                                </td>

                                <td>
                                    {fuelDrop.volume}
                                </td>

                                <td>
                                    {fuelDrop.unitCost}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
};

const OrderWetstock: FunctionComponent<OrderWetstockProps> = (props: OrderWetstockProps) => {
    const {setShowModal, showModal, tanksFetch, refreshTanks} = props;

    console.log(tanksFetch);

    if (tanksFetch.pending) {
        return <div>Loading</div>
    } else {
        if (tanksFetch.rejected) {
            return <div>Error</div>
        } else if (tanksFetch.fulfilled) {
            let tank = tanksFetch.value;

            let postAdjustmentButton = null, water = 0;
            if (tank.canPostAdjustment) {
                postAdjustmentButton = <button className="btn btn-primary" onClick={() => props.postAdjustment()}>
                    Post Adjustment
                </button>
            }

            let storagetankid = tank.storagetankid;
            let capacity = tank.capacity - tank.calculatedSoh;
            capacity = capacity < 0 ? 0 : capacity;

            //TODO more information
            let latestTankReadingDisplay = null;
            let waterContentDisplay = null;
            if (tank.latestTankReading) {
                water = tank.latestTankReading.watercontent;

                latestTankReadingDisplay = <React.Fragment>
                    <dt className="col-sm-3">Latest Tank Reading</dt>
                    <dd className="col-sm-9">{tank.latestTankReading.volume} - {tank.latestTankReading.localDateTime}</dd>
                </React.Fragment>

                waterContentDisplay = <React.Fragment>
                    <dt className="col-sm-3">Water Content</dt>
                    <dd className="col-sm-9">{tank.latestTankReading.watercontent}</dd>
                </React.Fragment>
            }

            return (
                <React.Fragment>
                    <div className="card mb-3">
                        <LoaderOverlay show={tanksFetch.refreshing}/>

                        <div className="card-body">
                            <h2 className="card-title">{tank.description} <small
                                className="text-muted">{tank.abbreviateddescription}</small>
                            </h2>
                            {/* TODO attribute not correct */}

                            <div className="d-flex flex-row bd-highlight mb-3">
                                <div className="p-2">
                                    <TankDisplay
                                        height={300}
                                        width={125}
                                        volume={tank.volume}
                                        capacity={tank.capacity}
                                        water={water}
                                    />
                                </div>
                                <div className="p-2">
                                    <div>
                                        <dl className="row">
                                            <dt className="col-sm-3">Grade</dt>
                                            <dd className="col-sm-9">{tank.gradeName}</dd>

                                            <dt className="col-sm-3">Capacity</dt>
                                            <dd className="col-sm-9">{tank.capacity}</dd>

                                            <dt className="col-sm-3">Volume</dt>
                                            <dd className="col-sm-9">{tank.volume}</dd>

                                            <dt className="col-sm-3">Ullage</dt>
                                            <dd className="col-sm-9">{tank.capacity - tank.volume}</dd>

                                            {waterContentDisplay}

                                            <dt className="col-sm-3">Variance</dt>
                                            <dd className="col-sm-9">{tank.variance}</dd>

                                            {latestTankReadingDisplay}
                                        </dl>

                                        {/*<p className="card-text"><small className="text-muted">Last updated 3 mins*/}
                                        {/*    ago</small>*/}
                                        {/*</p>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ul className="list-group list-group-flush">
                            <li className="list-group-item" style={{borderTopWidth: 1}}>
                                <h5 className="card-title">Drops
                                </h5>

                                <FuelDropDisplay fuelDrops={tank.lastFiveDrops}/>

                                <button className="btn btn-primary mr-2" onClick={() => setShowModal(storagetankid)}>
                                    Tank Drop
                                </button>
                            </li>
                            <li className="list-group-item">
                                <h5 className="card-title">
                                    Dips
                                </h5>

                                <TankReadingDisplay tankReadings={tank.lastFiveTankReadings}/>

                                <TankDipAction onSuccess={() => refreshTanks()} tank={tank}
                                               storageTankId={storagetankid}/>
                                {postAdjustmentButton}
                            </li>
                        </ul>
                    </div>
                    <WetstockModal tank={tank} show={showModal === tank.storagetankid}
                                   onClose={() => setShowModal(false)}>
                        <TankDrop tankId={tank.storagetankid}/>

                        <WetstockFormNewOrder
                            onCancel={() => setShowModal(false)}
                            onSubmit={() => {
                                setShowModal(false);
                                refreshTanks();
                            }}
                            suppliers={tank.dropdownSupplierOptions}
                            tank={tank}
                            id="wetstock-form"
                        />
                    </WetstockModal>
                </React.Fragment>
            );
        }
        return null;
    }
}

export default compose<OrderWetstockProps, {}>(
    withState('showModal', 'setShowModal', false),
    connect((props) => {
        // @ts-ignore
        const {siteId, tankId} = props;

        const refreshTanks = () => {
            return {
                tanksFetch: {
                    refreshing: true,
                    force: true,
                    comparison: siteId,
                    url: `/itemwetstock/${siteId}/tank/${tankId}`,
                }
            }
        };
        return {
            postAdjustment: () => {
                return {
                    postAdjustmentResponse: {
                        method: 'POST',
                        url: `/itemwetstock/${siteId}/adjustment`,
                        andThen: refreshTanks,
                        body: JSON.stringify({
                            storageTankId: tankId
                        })
                    }
                }
            },

            refreshTanks: refreshTanks,

            tanksFetch: {
                comparison: siteId,
                url: `/itemwetstock/${siteId}/tank/${tankId}`
            },
        }
    })
)(OrderWetstock);
