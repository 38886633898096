/**
 * Created by RDTS on 4/04/2017.
 */

import db from "./db";
import Decimal from "decimal.js";
import events from "./events";
import getCsrfToken from "../common/csrfToken";

// TODO this should not be accessing the DOM directly
const content = document.getElementById('content');

const syncUrl = content ? content.getAttribute('data-sync-url') : '';

const sales = {
    save(sale) {
        events.currentShiftEvent().then(shiftEvent => {
            if (shiftEvent && shiftEvent.eventType === events.shiftStart) {
                sale.shiftStartTime = shiftEvent.time;
                console.log(sale);
                return db.sales.add(sale).then(() => {
                    console.log('sale saved', sale);
                    this.calculateTill();
                    return sales.syncSales();
                });
            }
            return Promise.reject('Shift not started');
        });
    },

    calculateTill() {
        events.activeShift().then(activeShift => {
            if (activeShift) {
                console.log('active shift', activeShift);
                db.sales.toCollection().filter(event => {
                    return !event.synchronized
                }).toArray((sales) => {
                    let amount = new Decimal(activeShift.float);
                    for (let i = 0; i < sales.length; i++) {
                        const sale = sales[i];
                        // only count sales from the time the shift started
                        if (sale.time >= activeShift.time) {
                            console.log(sale);
                            for (let j = 0; j < sale.payments.length; j++) {
                                const payment = sale.payments[j];

                                if (payment.isCash) {
                                    amount = amount.add(payment.amount);
                                }
                            }
                            amount = amount.sub(sale.change);
                        }
                    }
                    if (typeof this.saleTotalCallback === "function") {
                        this.saleTotalCallback(amount.toNumber());
                    }
                })
            }
        });
    },

    getSales() {
        return db.sales.orderBy('time').toArray();
    },


    syncSales() {
        return db.sales.toCollection().filter(event => {
            return !event.synchronized
        }).toArray((sales) => {
            if (sales.length) {
                this.setState({syncing: true});

                let csrfToken = getCsrfToken();

                const req = new XMLHttpRequest();

                req.onreadystatechange = () => {
                    if (req.readyState == 4 && req.status == 200) {
                        const saleIds = JSON.parse(req.responseText).sales.success;

                        // remove the sale then call syncSales again
                        console.log(saleIds);
                        db.sales
                            .where("id")
                            .anyOf(saleIds)
                            .toArray(objs => console.log(objs));

                        db.sales
                            .where("id").anyOf(saleIds)
                            .modify({synchronized: true})
                            .then(syncCount => console.log("Synced " + syncCount + " sales"));
                        this.setState({syncing: false, syncError: false});

                    }
                    // else {
                    //     this.setState({syncing: false, syncError: true});
                    // }
                };

                req.open('POST', syncUrl, true);
                req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                req.setRequestHeader('X-CSRF-Token', csrfToken)
                req.setRequestHeader("X-Requested-With", "XMLHttpRequest");

                req.send(JSON.stringify({events: [], sales: sales}));
            }
        });
    }
}

export default sales;