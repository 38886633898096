import React, {useState} from "react";
import FormConnect from "../common/FormConnect";
import {Modal} from "react-bootstrap";
import {DateTimeSelect} from "./DateTimeSelect";

const uiSchema = {
    "storagetankid": {
        "ui:widget": "hidden"
    },
    "localDateTime": {
        "ui:widget": "hidden"
    },
    "volume": {
        "ui:autofocus": true
    },
};
//TODO date selection
const schema = {
    type: "object",
    required: ["storagetankid", "volume", "watercontent"],
    properties: {
        storagetankid: {type: "number"},
        volume: {type: "number", title: "Fuel Volume"},
        watercontent: {type: "number", title: "Water Content"},
        localDateTime: {type: "number"},
    }
};

export const TankDipModal = ({show, onClose, onSubmit, onCancel, storageTankId, tankName}) => {
    const [dateTime, setDateTime] = useState();

    let content = null;
    if (dateTime) {
        content = <FormConnect
            url="/tank/dip"
            noHtml5Validate
            noValidate
            schema={schema}
            uiSchema={uiSchema}
            onSuccess={onSubmit}
            model={{storagetankid: storageTankId, localDateTime: dateTime.format("YYYY-MM-DD HH:mm:ss")}}
        >
            <div className="clearfix">
                <div className="btn-toolbar pull-right">
                    <button type="submit" className="btn btn-primary">Save Dip</button>
                    <button type="button" className="btn btn-secondary" onClick={onCancel}>Cancel</button>
                </div>
            </div>
        </FormConnect>
    } else {
        content = <DateTimeSelect onSubmit={(value) => setDateTime(value)}/>;
    }

    return (
        <Modal show={show} onExited={() => setDateTime(undefined)} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Tank Dip <small>{tankName}</small></Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {content}
            </Modal.Body>
        </Modal>
    );
};

