import React from 'react';
import ReactDOM from 'react-dom';
import OrderWetstock from "./OrderWetstock";

export default () => {
    const containers = document.getElementsByClassName('order-wetstock');

    console.log(containers);

    if (containers.length > 0) {
        for (let i = 0; i < containers.length; i++) {
            let container = containers[i];

            const siteId = container.getAttribute('data-site-id');
            const tankId = container.getAttribute('data-tank-id');

            console.log(siteId, tankId, container)

            ReactDOM.render(
                <OrderWetstock siteId={siteId} tankId={tankId}/>,
                container
            )
        }
    }
};
