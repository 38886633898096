import baseAxios, {AxiosInstance} from "axios";
import getCsrfToken from "../common/csrfToken";

const axiosInstance: AxiosInstance = baseAxios.create({
    headers: {
        'X-CSRF-Token': getCsrfToken()
    }
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 403) {
        window.location.reload();
        return;
    }

    return Promise.reject(error);
});

export default axiosInstance;
