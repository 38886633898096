import {compose} from "recompose";
import connect from "../common/connect";
import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

class TankDrop extends PureComponent {
    render() {
        return (
            <div>
            </div>
        );
    }
}

// TODO should display outstanding orders with quantities
// should allow selection of outstanding order
// after selection allow actual volume to be changed below outstanding volume or ullage volume whichever less - could also allow a link to dip

export default compose(
    connect((props) => {
        const {tankId} = props;

        return {
            tanksFetch: {
                comparison: tankId,
                url: `/tank/orders/${tankId}`
            },

        }
    })
)(TankDrop);

TankDrop.propTypes = {
    tankId: PropTypes.number.isRequired
};
