import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../../app/store";
import {Department, getDepartmentList, getSearchItem, Item, makeAdjustment} from "../../api/stocktakeAPI";

interface StocktakeState {
    departmentsError: string | null
    searchError: string | null
    isDepartmentsLoading: boolean
    isSearchLoading: boolean
    departments: Department[]
    items: Item[]
    lastSearch?: any
}

let stocktakeInitialState: StocktakeState = {
    departments: [],
    items: [],
    // TODO should be false
    isDepartmentsLoading: true,
    isSearchLoading: false,
    departmentsError: null,
    searchError: null,
    lastSearch: {}
};

function startLoading(state: StocktakeState) {
    state.isDepartmentsLoading = true
}

function loadingFailed(state: StocktakeState, action: PayloadAction<string>) {
    state.isDepartmentsLoading = false;
    state.departmentsError = action.payload
}

const stocktakeSlice = createSlice({
    name: 'stocktake',
    initialState: stocktakeInitialState,
    reducers: {
        searchStart(state) {
            state.isSearchLoading = true
        },

        setLastSearch(state, {payload}) {
            state.lastSearch = payload;
        },
        searchSuccess(state, {payload}: PayloadAction<Item[]>) {
            state.items = payload;
            state.isSearchLoading = false;
            state.searchError = null;
        },
        searchFailure(state, {payload}: PayloadAction<string>) {
            state.isSearchLoading = false;
            state.searchError = payload;
        },
        getDepartmentsStart: startLoading,
        getDepartmentsSuccess(state, {payload}: PayloadAction<Department[]>) {
            state.departments = payload;
            state.isDepartmentsLoading = false;
            state.departmentsError = null;
        },

        getDepartmentsFailure: loadingFailed
    }
});

export const {
    searchStart,
    searchSuccess,
    searchFailure,
    getDepartmentsStart,
    getDepartmentsSuccess,
    getDepartmentsFailure,
    setLastSearch
} = stocktakeSlice.actions;

export const searchItems = (
    siteId: number,
    search: any
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        dispatch(searchStart());
        const items = await getSearchItem(siteId, search);
        dispatch(setLastSearch(search));
        dispatch(searchSuccess(items));
    } catch (err) {
        dispatch(searchFailure(err.toString()))
    }
};

export const saveStocktake = (siteId: number, data: any): AppThunk => async (dispatch, getState) => {
    const state = getState();

    try {
        dispatch(searchStart());
        await makeAdjustment(siteId, data);
        const items = await getSearchItem(siteId, state.stocktake.lastSearch);
        dispatch(searchSuccess(items));
    } catch (err) {
        dispatch(searchFailure(err.toString()))
    }
};


export const fetchDepartments = (
    siteId: number
): AppThunk => async (dispatch, getState) => {
    // const state = getState();

    try {
        dispatch(getDepartmentsStart());
        const departments = await getDepartmentList(siteId);
        dispatch(getDepartmentsSuccess(departments));
    } catch (err) {
        dispatch(getDepartmentsFailure(err.toString()))
    }
};

export default stocktakeSlice.reducer;
