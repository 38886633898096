import React, {PureComponent} from 'react';
import {branch, compose, renderNothing} from "recompose";

class ItemSearchResults extends PureComponent {
    render() {
        const {search, isBarcode} = this.props;

        let content, searchDisplay = <em>{search}</em>;
        if (isBarcode) {
            content = <div>
                Scanned Barcode: {searchDisplay}
            </div>
        } else {
            content = <div>
                Search for: {searchDisplay}
            </div>
        }

        return (
            content
        );
    }
}

export default compose(
    branch(
        ({search}) => {
            return (search === '' || search === undefined)
        },
        renderNothing
    )
)(ItemSearchResults)
