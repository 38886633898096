/**
 * Created by RDTS on 31/03/2017.
 */

import db from "./db";

const safeDrop = 'EVENTTYPE_SAFEDROP';

const events = {
    shiftStart: 'EVENTTYPE_SHIFT_START',
    shiftEnd: 'EVENTTYPE_SHIFT_END',
    eventSafeDrop: 'EVENTTYPE_SAFEDROP',

    sync() {
        // db.events.toCollection().modify({synchronized: true}).then(x => {
        //     console.log(x);
        // });
        db.events.toCollection().filter(event => {
            return !event.synchronized
        }).toArray((events) => {
            console.log(events);

            if (events.length) {

                const metaTags = document.getElementsByTagName('meta');
                let csrfToken;

                // get CSRF token from meta tag
                for (let i = 0; i < metaTags.length; i++) {
                    let metaTag = metaTags[i];
                    if (metaTag.name === 'csrf-token') {
                        csrfToken = metaTag.content;
                    }
                }

                const req = new XMLHttpRequest();

                req.onreadystatechange = () => {
                    if (req.readyState == 4 && req.status == 200) {
                        const data = JSON.parse(req.responseText)['events'];

                        // remove the sale then call syncSales again


                        db.events
                            .where("id")
                            .anyOf(data.success)
                            .toArray(objs => console.log(objs));

                        db.events
                            .where("id").anyOf(data.success)
                            .modify({synchronized: true})
                            .then(syncCount => console.log("Synced " + syncCount + " events"));
                    }
                };

                req.open('POST', document.getElementById('content').getAttribute('data-sync-url'), true);
                req.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                req.setRequestHeader('X-CSRF-Token', csrfToken);
                req.setRequestHeader("X-Requested-With", "XMLHttpRequest");

                req.send(JSON.stringify({events: events, sales: []}));
            }
        });
    },

    safeDrop(amount, cashierId, siteId) {
        this.currentShiftEvent().then(shiftEvent => {
            if (shiftEvent && shiftEvent.eventType === this.shiftStart) {
                return db.events.add({
                    eventType: safeDrop,
                    time: Date.now(),
                    amount: amount,
                    cashierId: cashierId,
                    shiftStartTime: shiftEvent.time,
                    siteId: siteId
                }).then(() => {
                    events.sync();
                });
            }
            return Promise.reject('Shift not started');
        });
    },

    allShiftEvents() {
        return db.events.orderBy('time').reverse().filter(event => {
            return event.eventType === this.shiftEnd || event.eventType === this.shiftStart
        });
    },

    currentShiftEvent() {
        return this.allShiftEvents().first();
    },

    activeShift() {
        return this.currentShiftEvent().then(shiftEvent => {
            if (!shiftEvent) {
                return false;
            }
            return shiftEvent.eventType === this.shiftStart ? shiftEvent : false
        }).catch(() => {
            return false;
        });
    },

    startShift(float, cashierId, siteId) {
        db.events.add({
            eventType: this.shiftStart,
            time: Date.now(),
            float: float,
            cashierId: cashierId,
            siteId: siteId
        }).then(() => {
            events.sync();
        });
    },

    endShift(cashierId, siteId) {
        this.currentShiftEvent().then(shiftEvent => {
            if (shiftEvent && shiftEvent.eventType === this.shiftStart) {
                return db.events.add({
                    eventType: events.shiftEnd,
                    time: Date.now(),
                    shiftStartTime: shiftEvent.time,
                    cashierId: cashierId,
                    siteId: siteId
                }).then(() => {
                    events.sync();
                });
            } else {
                return Promise.reject('No current shift.');
            }
        })

    }
};

export default events