import React, {Component} from "react";
// import "./pos.css";
import Decimal from "decimal.js";
// import ReactCSSTransitionGroup from "react-transition-group";

import ActionBar from "./ActionBar.jsx";
import ItemControls from "./ItemControls.jsx";
import LocalDebtors from "./LocalDebtors.jsx";
import MobileBar from "./MobileBar.jsx";
import PastSales from "./PastSales.jsx";
import PosMobileProducts from "./PosMobileProducts.jsx";
import PosSale from "./PosSale.jsx";
import Products from "./Products.jsx";
import QuickButtons from "./QuickButtons.jsx";
import Payment from "./Payment.jsx";
import PriceOverride from "./PriceOverride.jsx";
import update from "immutability-helper";
import generateUUID from "../helpers/generateUUID";
import sales from "../db/sales";
import BarcodeScanning from "../helpers/barcodeScanning";
import Modal from "./Modal";
import Login from "./Login";
import MainControls from "./MainControls";
import Clock from "./Clock";
import events from "../db/events";
import Receipt from "./Receipt";
import ShiftForm from "./ShiftForm";
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import PropTypes from "prop-types";

const favourites = 'Favourites';
const products = 'All Products';
const localDebtors = 'Customers';
const pastSales = 'Past Sales';


export default class Pos extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numberOfSales: 0,
            data: [],
            cart: [],
            payments: [],
            showMain: true,
            pastSales: [],
            showModal: false,
            modalContent: '',
            itemButtonGrid: [],
            modifyProduct: false,
            tabContent: favourites,
            saleActive: true,
            shiftActive: false,
            loggedInCashier: false
        };

        this.handleSelectItem = this.handleSelectItem.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handleSaveItem = this.handleSaveItem.bind(this);
        this.handleButtonItemClick = this.handleButtonItemClick.bind(this);
        this.handleAddItem = this.handleAddItem.bind(this);
        this.handleRemovePayment = this.handleRemovePayment.bind(this);
        this.handleRemoveItem = this.handleRemoveItem.bind(this);
        this.handleShowModalContent = this.handleShowModalContent.bind(this);
        this.handlePay = this.handlePay.bind(this);
        this.handleSelectPay = this.handleSelectPay.bind(this);
        this.handleCancelModify = this.handleCancelModify.bind(this);
        this.handleSelectSale = this.handleSelectSale.bind(this);
        this.handlePayFinal = this.handlePayFinal.bind(this);
        this.handleNewSaleClick = this.handleNewSaleClick.bind(this);
        this.receiveMessage = this.receiveMessage.bind(this);
        this.onSwitch = this.onSwitch.bind(this);
        this.resetOrder = this.resetOrder.bind(this);
        this.selectSale = this.selectSale.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleDepartmentButtonClick = this.handleDepartmentButtonClick.bind(this);
        this.handleCashierLogin = this.handleCashierLogin.bind(this);
        this.handleCashierLogout = this.handleCashierLogout.bind(this);
        this.handleTabContentSelect = this.handleTabContentSelect.bind(this);
        this.handleShiftStart = this.handleShiftStart.bind(this);
        this.handleShiftEnd = this.handleShiftEnd.bind(this);
        this.handleShiftStartRequest = this.handleShiftStartRequest.bind(this);

        sales.setState = this.setState.bind(this);
    }

    handleSelectItem(index) {
        if (!this.state.saleActive) {
            return;
        }

        this.setState({
            modifyProduct: index
        });
    }

    handleQuantityChange(index, qty, unitPriceOverride) {
        // TODO unitPriceOverride validity checking
        let upObj = {};
        if (unitPriceOverride) {
            upObj[index] = {
                qty: {$set: qty},
                unitPriceOverride: {$set: new Decimal(unitPriceOverride)}
            };
        } else {
            upObj[index] = {
                qty: {$set: qty},
            };
        }

        this.setState({
            cart: update(this.state.cart, upObj),
            modifyProduct: false
        });
    }

    handleSaveItem(index, qty, answers, product) {
        let newCart;
        const cart = this.state.cart;

        if (index !== undefined) {
            if (qty) {
                const upObj = {};
                upObj[index] = {qty: {$set: qty}, answers: {$set: answers}};
                // deselect on save
                newCart = update(cart, upObj);
            } else {
                newCart = update(this.state.cart, {$splice: [[index, 1]]})
            }
        } else {
            newCart = update(cart, {$push: [{product: product, qty: 1, answers: answers}]})
        }
        this.setState({cart: newCart, action: undefined});
    }

    findProduct(id) {
        var data = this.state.data,
            product;

        for (var i = 0; i < data.length; i++) {
            product = data[i];
            if (product.id == id) {
                return product;
            }
        }
    }

    handleButtonItemClick(itemButtonId) {
        var items = this.state.buttonToItem[itemButtonId];

        if (items.length === 1) {
            this.handleAddItem(items[0]);
        } else {
            this.setState({showModal: true});

            var itemButton;
            for (var i = 0; i < this.state.itemButtons.length; i++) {
                var obj = this.state.itemButtons[i];
                if (obj.itembuttonid == itemButtonId) {
                    itemButton = obj;
                }
            }

            // note this modalContent cannot be re-rendered by react

            var buttons = items.map((itemId) => {
                var item = this.findProduct(itemId);
                return <button style={{height: 40}} onClick={this.handleAddItem.bind(this, itemId)}
                               className="btn btn-pos-menu btn-pos">{item.name}</button>
            });

            const modalContent =
                <ModalContent>
                    <ModalHeader>
                        {itemButton.itembuttonlabel}
                    </ModalHeader>
                    <div style={{maxHeight: 470, overflowY: 'auto'}}>
                        <ModalBody>
                            {buttons}
                        </ModalBody>
                    </div>
                    <ModalFooter>
                        <button className="btn btn-default" onClick={this.closeModal}>Cancel</button>
                    </ModalFooter>
                </ModalContent>

            this.setState({
                modalContent: modalContent
            });
            // show dialog of items
        }
    }

    addItem(id, qtyToAdd, unitPriceOverride) {
        // hide modal if addItem was called from multi-product modal
        this.setState({selectedSale: undefined, showModal: false});

        // if sale is inactive remove payments and set cart to empty
        let cart;
        if (!this.state.saleActive) {
            this.setState({
                payments: [],
                saleActive: true
            });
            cart = [];
        } else {
            cart = this.state.cart;
        }

        // find product
        let cartIndex = -1, samePrice = true;
        for (var i = 0; i < cart.length; i++) {
            // skip items with defined total price
            if (!cart[i].totalPrice && cart[i].product.id == id) {
                cartIndex = i;
                if (cart[i].unitPriceOverride && unitPriceOverride) {
                    samePrice = cart[i].unitPriceOverride.equals(unitPriceOverride);
                    // exit loop when we've found an item with the same override price
                    break;
                }
            }
        }

        const product = this.findProduct(id);

        if (!unitPriceOverride && product.price.toNumber() === 0) {
            // if the product price is 0 render the PriceOverride dialog
            // this dialog will call addItem again but with a totalPrice
            const modalContent = <PriceOverride
                productName={product.name}
                productId={product.id}
                onAddItem={this.addItem.bind(this)}
                onCancel={this.closeModal}
            />;

            // setting the cart intentional
            this.setState({
                showModal: true,
                modalContent: modalContent,
                cart: cart
            });
        } else if (cartIndex !== -1 && samePrice) {
            // if non totalPrice item is already in cart update the quantity
            const upObj = {};
            upObj[cartIndex] = {
                qty: {
                    $apply: qty => qty + qtyToAdd
                }
            };

            this.setState({
                cart: update(cart, upObj)
            });
        } else {
            // adding new product in cart or a price override product with a different overriden price
            const newState = update(cart, {
                $push: [{
                    product: product,
                    qty: qtyToAdd,
                    unitPriceOverride: unitPriceOverride ? new Decimal(unitPriceOverride) : undefined
                }]
            });
            this.setState({cart: newState});
        }
    }

    handleShiftStartRequest() {
        // if there isn't already an active shift show the shift modal
        events.activeShift().then((active) => {
            if (!active) {
                this.showShiftModal();
            }

            this.setState({
                shiftActive: !!active
            });
        });
    }

    showShiftModal() {
        const shiftForm = <ShiftForm
            cashierId={this.state.loggedInCashier}
            siteId={this.props.siteId}
            onStart={this.handleShiftStart}
            onCloseModal={this.closeModal}
        />;

        this.setState({
            modalContent: shiftForm,
            showModal: true,
        });
    }

    handleAddItem(id) {
        // check if a shift has been started before attempting to add an item to the sale
        // if there is no shift show the shift start modal
        events.activeShift().then((active) => {
            if (active) {
                this.addItem(id, 1);
            } else {
                this.showShiftModal();
            }

            this.setState({
                shiftActive: active
            })
        });
    }

    handleShiftStart() {
        this.setState({
            shiftActive: true,
            showModal: false
        });
    }

    handleShiftEnd() {
        const modalContent = <ModalContent>
            <ModalHeader>
                End Shift
            </ModalHeader>
            <ModalBody>
                <p>Are you sure you want to end the shift?</p>
            </ModalBody>
            <ModalFooter>
                <div className="clearfix">
                    <div className="btn-toolbar pull-right">
                        <button className="btn btn-success" onClick={this.handleShiftEndFinal.bind(this)}>End Shift
                        </button>
                        <button className="btn btn-default" onClick={this.closeModal}>Cancel</button>
                    </div>
                </div>
            </ModalFooter>
        </ModalContent>;

        this.setState({
            showModal: true,
            modalContent: modalContent
        })
    }

    handleShiftEndFinal() {
        events.activeShift().then(active => {
            console.log(active);
            if (active) {
                events.endShift(this.props.cashierId, this.props.siteId);
            }

            this.setState({
                shiftActive: false,
                showModal: false,
            });
        });
    }

    handleRemovePayment(index) {
        this.setState({
            payments: update(this.state.payments, {$splice: [[index, 1]]})
        });
    }

    handleRemoveItem(index) {
        this.setState({
            cart: update(this.state.cart, {$splice: [[index, 1]]})
        });

        this.setState({modifyProduct: false});
    }

    handlePay() {
        // check if a shift has been started before attempting to make a payment
        // if there is no shift show the shift start modal
        events.activeShift().then((active) => {
            if (active) {
                const modalContent =
                    <Payment
                        contactToLocalDebtor={this.state.contactToLocalDebtor}
                        localDebtorCards={this.state.localDebtorCards}
                        mops={this.state.mops}
                        onRemovePayment={this.handleRemovePayment}
                        onCancel={this.closeModal}
                        onSelectPay={this.handleSelectPay}
                        onPayFinal={this.handlePayFinal}
                        total={this.currentAmountDue()}
                        previous={this.state.payments}
                    />

                this.setState({
                    modalContent: modalContent,
                    showModal: true,
                });
            } else {
                this.showShiftModal();
            }

            this.setState({
                shiftActive: active
            })
        });
    }

    handleSelectPay(method) {
        const payment = <Payment
            contactToLocalDebtor={this.state.contactToLocalDebtor}
            localDebtorCards={this.state.localDebtorCards}
            mops={this.state.mops}
            onRemovePayment={this.handleRemovePayment}
            onCancel={this.closeModal}
            method={method}
            onSelectPay={this.handleSelectPay}
            onPayFinal={this.handlePayFinal}
            total={this.currentAmountDue()}
            previous={this.state.payments}
        />;

        this.setState({
            modalContent: payment,
            showModal: true,
        });
    }

    paymentsTotal(payments) {
        return payments.reduce(function (total, current) {
            return current.amount.add(total);
        }, new Decimal(0));
    }

    currentAmountDue() {
        const amountDue = this.calculateCart().total.sub(this.currentPaymentsTotal());

        return amountDue.toNumber() < 0 ? new Decimal(0) : amountDue;
    }

    currentPaymentsTotal() {
        return this.paymentsTotal(this.state.payments);
    }

    handleCancelModify() {
        this.setState({modifyProduct: false});
    }

    handlePayFinal(amount, method, card) {
        const isCash = method.moptypeid == "MOPTYPE_CASH";
        // round on cash payment
        amount = isCash ? new Decimal(amount).toDP(1) : new Decimal(amount);

        // change = current change (unrounded) from previous payments (if any) + payment just made
        const payments = update(this.state.payments, {
            $push: [{
                method: method.mopid,
                description: method.description,
                isCash: isCash,
                amount: amount,
                cardNumber: card ? card.cardNumber : undefined,
                organisationName: card ? card.organisationname : undefined
            }]
        });


        const cartTotal = this.calculateCart().total;
        const paymentsTotal = this.paymentsTotal(payments);
        let change, rounding = new Decimal(0), amountDue;

        if (isCash) {
            // round cart on cash payment
            var roundedCart = cartTotal.toDP(1);
            change = paymentsTotal.sub(roundedCart)
            rounding = roundedCart.sub(cartTotal)
            amountDue = roundedCart;
        } else {
            change = paymentsTotal.sub(cartTotal);
            amountDue = cartTotal;
        }


        // console.log(
        //     'payments', paymentsTotal.toNumber(),
        //     'change', change.toNumber(),
        //     'rounding', rounding.toNumber(),
        //     'cash', isCash,
        //     'cart total', cartTotal.toNumber()
        // );
        // if the change of the sale is now larger or equal to zero the sale has been finalized
        if (change.toNumber() >= 0) {
            // sale has been finalized - process sale
            // transform the current cart to be posted
            const cart = this.state.cart.map((cartItem) => {
                const product = cartItem.product;
                // check for unit price override - if there is one set the unit price to it
                const unitPrice = cartItem.unitPriceOverride ? cartItem.unitPriceOverride : product.price;
                const totalPrice = unitPrice.mul(cartItem.qty);

                return {
                    itemId: product.id,
                    name: product.name,
                    tax: totalPrice.sub(this.calcCartItemTax(cartItem)).toNumber(),
                    totalPrice: totalPrice.toNumber(),
                    unitPrice: unitPrice.toNumber(),
                    qty: cartItem.qty
                };
            });

            const d = new Date();
            const transactionNumber = this.props.transactionNumberBase + this.state.numberOfSales;

            // format payments for submission
            const submitPayments = payments.map((payment) => {
                return {
                    amount: payment.amount.toNumber(),
                    method: payment.method,
                    cardNumber: payment.cardNumber,
                    organisationName: payment.organisationName,
                    isCash: isCash,
                    description: payment.description
                };
            });

            // End receipt formatting
            const sale = {
                receiptFooter: this.props.receiptFooter,
                receiptHeader: this.props.receiptHeader,
                id: generateUUID(),
                siteId: this.props.siteId,
                change: change.toDP(1).toNumber(),
                totalPaid: this.paymentsTotal(payments).toNumber(),
                payments: submitPayments,
                time: d.getTime(),
                cart: cart,
                rounding: rounding.toNumber(),
                taxTotal: this.calculateTotalTax().toNumber(),
                // TODO rounding
                saleTotal: cartTotal.toNumber(),
                amountDue: amountDue.toNumber(),
                transactionNumber: transactionNumber,
                posStationName: this.props.posStationName,
                posStationId: this.props.posStationId
            };


            const cashier = this.findCashier();
            if (cashier) {
                events.activeShift().then(shiftEvent => {
                    sale.cashierId = cashier.contactid;
                    sale.cashierFirstName = cashier.contactfirstname;
                    sale.cashierLastName = cashier.contactlastname;
                    // add shift start time to sale before saving
                    sale.shiftStart = shiftEvent.time;
                    sales.save(sale);

                    this.setState({
                        showModal: false,
                        numberOfSales: this.state.numberOfSales + 1,
                        payments: payments,
                        saleActive: false,
                        pastSales: update(this.state.pastSales, {$push: [sale]}),
                        action: {type: 'saleFinal'},
                        lastSale: sale
                    });
                }).catch(() => console.error('no shift on sale save'));
            }


        } else {
            // sale has not been finalized
            this.setState({
                showModal: false,
                action: {type: 'pay'},
                payments: payments,
            });
        }
    }

    // step one calculate difference between paid amount and amount due
    // step two if the amount is positive then round the

    handleNewSaleClick() {
        this.setState({
            saleActive: true,
            payments: [],
            cart: []
        });
    }

    loadLocalDebtors(localDebtorData) {
        var localDebtor, contactToLocalDebtor = {};
        for (var i = 0; i < localDebtorData.length; i++) {
            localDebtor = localDebtorData[i];
            contactToLocalDebtor[localDebtor.contactid] = localDebtor;
        }

        this.setState({
            localDebtors: localDebtorData,
            contactToLocalDebtor: contactToLocalDebtor
        });
    }

    loadLocalDebtorCards(localDebtorCardData) {
        this.setState({
            localDebtorCards: localDebtorCardData
        })
    }

    loadProducts(productData, departmentData, taxRateData) {
        var taxRate, taxRates = {};
        for (var x = 0; x < taxRateData.length; x++) {
            taxRate = taxRateData[x];
            taxRates[taxRate.taxrateid] = taxRate;
        }

        // departments are mapped departmentid: departmentdata

        let department;
        const departments = {};

        for (var z = 0; z < departmentData.length; z++) {
            department = departmentData[z];
            departments[department.departmentid] = department;
        }

        const products = [], departmentToItem = {};

        for (var i = 0; i < productData.length; i++) {
            const product = productData[i];
            const productDepartment = departments[product.departmentid];
            const productTaxRate = taxRates[productDepartment.taxrateid];
            const itemid = product.itemid;

            if (departmentToItem[product.departmentid]) {
                departmentToItem[product.departmentid].push(itemid);
            } else {
                departmentToItem[product.departmentid] = [itemid];
            }

            products.push({
                id: itemid,
                name: product.description,
                price: new Decimal(product.unitprice),
                department: product.departmentid,
                departmentname: productDepartment.description,
                taxrate: new Decimal(productTaxRate.taxratepercent),
                alcohol: product.isalcohol,
                tobacco: product.istobacco,
                itemtype: product.itemtypeid,
                sku: product.sku,
                taxdescription: productTaxRate.description,
            });
        }
        this.setState({
            data: products,
            departments: departments,
            taxRates: taxRates,
            departmentToItem: departmentToItem
        });
    }

    loadMops(mopData) {
        this.setState({mops: mopData});
    }

    loadBarcodes(barcodeData) {
        // Create an object that maps barcodes to item ids for fast lookup on barcode add / search
        let barcode;
        const barcodeToItem = {};
        for (let i = 0; i < barcodeData.length; i++) {
            barcode = barcodeData[i];
            barcodeToItem[barcode.barcode] = barcode.itemid;
        }

        this.setState({barcodes: barcodeData, barcodeToItem: barcodeToItem});
    }

    loadItemButtons(itemButtons, buttonMappedItems) {
        const buttonToItem = {};
        for (let x = 0; x < buttonMappedItems.length; x++) {
            const curButtonItem = buttonMappedItems[x];
            const prevItemId = buttonToItem[curButtonItem.itembuttonid];

            // if there has already been an item added to a particular button then push to the array of items
            // otherwise create a new array of items
            if (prevItemId) {
                buttonToItem[curButtonItem.itembuttonid].push(curButtonItem.itemid);
            } else {
                buttonToItem[curButtonItem.itembuttonid] = [curButtonItem.itemid];
            }
        }

        this.setState({buttonToItem: buttonToItem});

        // calculate the size in rows and cols of the button grid
        const size = itemButtons.reduce(function (prev, current) {
            return {
                cols: prev.cols > current.column ? prev.cols : current.column,
                rows: prev.rows > current.row ? prev.rows : current.row
            }
        }, {cols: 0, rows: 0});

        // fill button grid matrix
        const itemButtonsGrid = new Array(size.rows).fill(undefined).map(function () {
            return new Array(size.cols).fill(undefined)
        }.bind(this));

        // add buttons to their locations in the button grid matrix
        let itemButton;
        for (let i = 0; i < itemButtons.length; i++) {
            itemButton = itemButtons[i];
            itemButtonsGrid[itemButton.row - 1][itemButton.column - 1] = itemButton;
        }

        this.setState({itemButtonGrid: itemButtonsGrid, itemButtons: itemButtons});
    }

    loadData() {
        const req = new XMLHttpRequest();
        req.onreadystatechange = () => {
            if (req.readyState == 4 && req.status == 200) {
                const data = JSON.parse(req.responseText);
                this.parseData(data);
            }
        };

        req.open('GET', this.props.dataUrl + '?id=' + this.props.siteId + '&_' + Date.now(), true);
        req.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        req.send();
    }

    loadClerks(clerks) {
        const contactIdToClerk = {};
        for (var i = 0; i < arguments.length; i++) {
            var clerk = arguments[i];
            contactIdToClerk[clerk.contactid] = clerk;
        }

        this.setState({
            contactIdToClerk: contactIdToClerk,
            clerks: clerks
        });
    }

    loadPosStation(posStations) {
        // find the web pos posstation
        for (var i = 0; i < posStations.length; i++) {
            let posStation = posStations[i];
            if (this.props.posStationId == posStation.posstationid) {
                this.setState({posStation: posStation});
            }
        }
    }

    loadPosFunctions(posFunctions) {
        let safeDrop;
        for (var i = 0; i < posFunctions.length; i++) {
            var posFunction = posFunctions[i];
            if (posFunction.itemtypeid === 'ITEMTYPE_SAFEDROP') {
                safeDrop = posFunction;
            }
        }

        this.setState({
            posFunctions: posFunctions,
            safeDrop: safeDrop
        });
    }

    parseData(data) {
        this.loadProducts(data.drystockitems || [], data.departments || [], data.taxrates || []);
        this.loadMops(data.mops || data.mops);
        this.loadLocalDebtors(data.localdebtors || []);
        this.loadBarcodes(data.barcodes || []);
        this.loadLocalDebtorCards(data.localdebtorcards || []);
        this.loadClerks(data.clerks || []);
        this.loadPosStation(data.posstations || []);
        this.loadPosFunctions(data.posfunctions || []);
        // this.loadItemButtons(data.itembuttons, data.buttonmappeditems.concat(data.departmentbuttons));
        // merge itembuttons and departmentbuttons

        var itemButtonsData = data.itembuttons || [],
            departmentButtonsData = data.departmentbuttons || [];

        var itemButtons = itemButtonsData.map((itembutton) => {
            return {
                row: itembutton.itembuttonrow,
                column: itembutton.itembuttoncolumn,
                label: itembutton.itembuttonlabel,
                itembuttonid: itembutton.itembuttonid
            }
        }).concat(departmentButtonsData.map((departmentbutton) => {
            return {
                row: departmentbutton.departmentbuttonrow,
                column: departmentbutton.departmentbuttoncolumn,
                label: departmentbutton.departmentbuttonlabel,
                departmentid: departmentbutton.departmentid
            }
        }));

        this.loadItemButtons(itemButtons || [], data.buttonmappeditems || []);
    }

    safeDropWarning() {

    }

    safeDropCheck(amount) {
        // check for safe drop item in state
        if (!this.state.safeDrop) {
            return;
        }

        // console.log('safedrop check', amount);
        // amount is the total amount of cash currently in the till
        // we check if the cash amount - if it is we prompt for a safe drop
        // turn amount into Decimal object
        amount = new Decimal(amount);
        // calculate the amount over safe drop level that the till is
        const safeDropLevel = this.state.posStation.safedroprequiredlevel;

        const safeDropWarning = this.state.posStation.safedropwarninglevel;

        this.setState({
            safeDropWarning: amount.sub(safeDropWarning).toNumber() > 0,
        })


        const overSafeDrop = amount.sub(safeDropLevel).toNumber();
        // we are over the safe drop level prompt user for a safe drop
        // console.log(safeDropLevel, amount, overSafeDrop);


        // if (overSafeDrop > 0) {
        //     this.setState({
        //         showModal: true,
        //         modalContent: <SafeDrop
        //             minSafeDrop={overSafeDrop}
        //             closeModal={this.closeModal}
        //             cashierId={this.state.loggedInCashier}
        //             siteId={this.props.siteId}
        //         />
        //     });
        // }
    }

    componentDidMount() {
        sales.getSales().then((sales) => {
            this.setState({
                pastSales: sales
            });
        });

        sales.saleTotalCallback = this.safeDropCheck.bind(this);
        this.loadData();
        window.addEventListener("message", this.receiveMessage, false);
        setInterval(this.syncSales, 10000);

        BarcodeScanning(barcode => {
            var itemId = this.state.barcodeToItem[barcode];
            if (typeof (itemId) != "undefined") {
                this.handleAddItem(itemId);
                // console.log("added", barcode);
            } else {
                this.setState({
                    showModal: true,
                    modalContent: <p>Product with barcode: {barcode} does not exist.</p>
                });
                // console.log("not found", barcode)
            }
        });

        events.activeShift().then(active => {
            this.setState({
                shiftActive: active,
            });
        });
    }

    receiveMessage(event) {
        var method = event.data.method,
            params = event.data.params;


        if (!(method && params)) {

            return;
        }

        if (method == "add-item") {
            var quantity = params.quantity || 1;

            this.addItem(params.item_id, quantity, params.total_price);
        }
    }

    onSwitch() {
        this.setState({
            showMain: !this.state.showMain,
            action: undefined
        });
    }

    calcCartItemTax(cartItem) {
        // convert tax rate from 12.34% to 1.1234
        const taxRate = cartItem.product.taxrate.add(100).div(100);

        // if there is a unit price override in the cart item use it for
        // the unit price instead of the product's price
        let unitPrice;
        if (cartItem.unitPriceOverride) {
            unitPrice = cartItem.unitPriceOverride;
        } else {
            unitPrice = cartItem.product.price;
        }
        // calculate the line item price
        const lineItemPrice = unitPrice.mul(cartItem.qty);
        return lineItemPrice.sub(lineItemPrice.div(taxRate));
    }

    resetOrder() {
        this.setState({cart: [], payments: [], action: undefined});
    }

    calculateTotalTax() {
        return this.state.cart.reduce((total, current) => {
            return this.calcCartItemTax(current).add(total)
        }, new Decimal(0));
    }


    calculateCart() {
        const cart = this.state.cart;

        // calculate cart total
        const total = new Decimal(cart.reduce((total, current) => {
            if (current.unitPriceOverride) {
                return total.add(current.unitPriceOverride.mul(current.qty))
            }
            return total.add(current.product.price.mul(current.qty))
        }, new Decimal(0)).toDP(2));

        // calculate number of items in cart
        const quantity = cart.reduce((total, current) => {
            return total + current.qty
        }, 0);

        // Total and tax are both decimal objects, quantity is
        return {
            total: total,
            quantity: quantity,
        };
    }

    selectSale(index) {
        this.setState({selectedSale: index});
    }

    closeModal() {
        this.setState({showModal: false});
    }

    handleTabContentSelect(tab) {
        this.setState({
            tabContent: tab
        });
    }

    handleDepartmentButtonClick(departmentid) {
        const items = this.state.departmentToItem[departmentid];
        const department = this.state.departments[departmentid];

        // note this modalContent cannot be re-rendered by react

        const buttons = items.map(itemId => {
            const item = this.findProduct(itemId);
            if (item) {
                return <button style={{height: 40}} onClick={this.handleAddItem.bind(this, itemId)}
                               className="btn btn-pos-menu btn-pos">{item.name}</button>
            }
        });

        const modalContent = <ModalContent>
            <ModalHeader>
                {department.description}
            </ModalHeader>
            <div style={{maxHeight: 470, overflowY: 'auto'}}>
                <ModalBody>
                    {buttons}
                </ModalBody>
            </div>
            <ModalFooter>
                <button className="btn btn-default" onClick={this.closeModal}>Cancel</button>
            </ModalFooter>
        </ModalContent>;
        this.setState({
            showModal: true,
            modalContent: modalContent
        });
    }

    findClerkByUsername(username) {
        let clerk;
        for (let i = 0; i < this.state.clerks.length; i++) {
            if (this.state.clerks[i].posusername === username) {
                clerk = this.state.clerks[i];
            }
        }
        return clerk;
    }

    handleCashierLogin(credentials) {
        let clerk = this.findClerkByUsername(credentials.username);

        if (!clerk) {
            return false;
        }

        if (clerk.posuserpassword === credentials.password) {
            this.setState({
                loggedInCashier: clerk.contactid
            });
            return true;
        }
        return false;
    }

    findCashier() {
        for (let i = 0; i < this.state.clerks.length; i++) {
            const clerk = this.state.clerks[i];
            if (clerk.contactid === this.state.loggedInCashier) {
                return clerk;
            }
        }
        return undefined;
    }

    handleCashierLogout() {
        this.setState({
            loggedInCashier: undefined
        })
    }

    handleSelectSale(sale) {
        this.setState({
            selectedSale: sale
        });
    }

    handleShowModalContent(content) {
        this.setState({
            showModal: true,
            modalContent: content
        })
    }

    getSelectedSale() {
        const pastSales = this.state.pastSales;

        for (let i = 0; i < pastSales.length; i++) {
            let pastSale = pastSales[i];
            if (pastSale.id === this.state.selectedSale) {
                return pastSale;
            }
        }
    }

    render() {
        let controls,
            pageId,
            mobileControls,
            action = this.state.action,
            cartSummary = this.calculateCart(),
            invoice = <PosSale
                lastSale={this.state.lastSale}
                amountDue={this.currentAmountDue()}
                mops={this.state.mops}
                payments={this.state.payments}
                saleActive={this.state.saleActive}
                siteName={this.props.siteName}
                selected={action && action.index}
                cart={this.state.cart}
                onSelectItem={this.handleSelectItem}
                cartSummary={cartSummary}
                onPay={this.handlePay}
                locale={this.props.locale}
                address={this.props.address}
                pastSales={this.state.pastSales}
                selectSale={this.selectSale}
                selectedSale={this.state.selectedSale}
                taxNumber={this.props.taxNumber}
                onRemovePayment={this.handleRemovePayment}
                onReset={this.resetOrder}
                onNewSale={this.handleNewSaleClick}
            />;

        let tabContent;
        const selectedTabContent = this.state.tabContent;

        if (selectedTabContent === favourites) {
            tabContent = <QuickButtons
                onAddItem={this.handleAddItem}
                itemButtons={this.state.itemButtonGrid}
                onButtonItemClick={this.handleButtonItemClick}
                onDepartmentButtonClick={this.handleDepartmentButtonClick}
                data={this.state.data}
            />;
        } else if (selectedTabContent === localDebtors) {
            tabContent = <LocalDebtors localDebtors={this.state.localDebtors}/>;
        } else if (selectedTabContent === pastSales) {
            tabContent = <PastSales
                receiptHeader={this.props.receiptHeader}
                receiptFooter={this.props.receiptFooter}
                pastSales={this.state.pastSales}
                address={this.props.address}
                taxNumber={this.props.taxNumber}
                siteName={this.props.siteName}
                locale={this.props.locale}
                onSelectSale={this.handleSelectSale}
                onShowModalContent={this.handleShowModalContent}
            />;
        } else if (selectedTabContent === products) {
            tabContent = <Products
                onAddItem={this.handleAddItem}
                onButtonItemClick={this.handleButtonItemClick}
                data={this.state.data}
            />;
        }

        if (this.state.showMain) {
            mobileControls =
                <PosMobileProducts
                    onAddItem={this.handleAddItem}
                    data={this.state.data.slice(0, 20)}
                />;
            pageId = 'controls';
        } else {
            mobileControls = invoice;
            pageId = 'invoice';
        }

        let modal;
        if (this.state.showModal) {
            modal =
                <Modal show={this.state.showModal} cancel={this.closeModal}>
                    {this.state.modalContent}
                </Modal>
        } else if (this.state.modifyProduct !== false) {
            // when modifyProduct is set to the index of an item in the cart a modify product dialog is opened
            // any open modal takes precedence over the modify product dialog - e.g. incorrect barcode dialog
            const selected = this.state.modifyProduct;

            modal =
                <Modal show={true} cancel={this.handleCancelModify}>
                    <ItemControls
                        newRecord={false}
                        onDeleteItem={this.handleRemoveItem.bind(this, selected)}
                        onSave={this.handleSaveItem}
                        item={this.state.cart[selected]}
                        index={selected}
                        onQuantiyChange={this.handleQuantityChange}
                        onCancel={this.handleCancelModify}
                    />
                </Modal>
        }

        // override modal with cashier log in
        if (!this.state.loggedInCashier) {
            modal = <Modal show={true}>
                <Login
                    onLogin={this.handleCashierLogin}
                />
            </Modal>
        }

        let selectedSale = this.getSelectedSale(), receipt;
        if (this.state.pastSales.length > 0) {
            let saleIndex, duplicate;
            if (typeof selectedSale !== 'undefined') {
                duplicate = true;
            } else {
                duplicate = false;
                selectedSale = this.state.pastSales[this.state.pastSales.length - 1];
            }

            receipt = <Receipt
                transactionNumber={selectedSale.transactionNumber}
                siteName={this.props.siteName}
                address={this.props.address}
                taxNumber={this.props.taxNumber}
                cart={selectedSale.cart} payments={selectedSale.payments}
                taxTotal={selectedSale.taxTotal} time={selectedSale.time}
                totalPaid={selectedSale.totalPaid}
                saleTotal={selectedSale.saleTotal}
                change={selectedSale.change}
                rounding={selectedSale.rounding}
                locale={this.props.locale}
                receiptHeader={this.props.receiptHeader}
                receiptFooter={this.props.receiptFooter}
                cashierFirstName={selectedSale.cashierFirstName}
                cashierLastName={selectedSale.cashierLastName}
                duplicate={duplicate}
            />;
        }

        return (
            <div>
                <div className="pos hidden-print">
                    {modal}
                    <div className="hidden pos-col mobile">
                        <MobileBar
                            onSwitch={this.onSwitch}
                            resetOrder={this.resetOrder}
                            showMain={this.state.showMain}
                            cartSummary={cartSummary}
                        />
                        {/*<ReactCSSTransitionGroup*/}
                        {/*    transitionName={'slide'}*/}
                        {/*    transitionEnterTimeout={350}*/}
                        {/*    transitionLeave={false}*/}
                        {/*>*/}
                            <div className={"mobile-page"} key={pageId}>
                                {mobileControls}
                            </div>
                        {/*</ReactCSSTransitionGroup>*/}
                    </div>

                    <div>

                        <div className="pos-col invoice">
                            <div style={{height: 41}}>
                                <Clock locale={this.props.locale} date={true}/>
                                <span className="pull-right">
                            <span className="glyphicon glyphicon-shopping-cart"/>
                            <small style={{position: 'relative', top: -6}}>{cartSummary.quantity}</small>
                            </span>
                            </div>
                            <div className="pos-tab">
                                {invoice}
                            </div>
                        </div>
                        <div className="pos-col controls">
                            <MainControls onSelect={this.handleTabContentSelect}
                                          options={[favourites, products, localDebtors, pastSales]}
                                          locale={this.props.locale}
                                          cartQuantity={cartSummary.quantity} selected={this.state.tabContent}/>
                            <div className="pos-main-content pos-tab">
                                {tabContent}
                            </div>
                        </div>
                    </div>
                    <ActionBar
                        itemsAdded={!!this.state.cart.length}
                        saleActive={this.state.saleActive}
                        syncing={this.state.syncing}
                        syncError={this.state.syncError}
                        onShiftStart={this.handleShiftStartRequest}
                        onShiftEnd={this.handleShiftEnd}
                        onLockScreen={this.handleCashierLogout}
                        cashierId={this.state.loggedInCashier}
                        siteId={this.props.siteId}
                        shiftActive={!!this.state.shiftActive}
                    />
                </div>
                <div className="visible-print-block">
                    <div style={{top: 0, left: 0, right: 0, position: "fixed"}}>
                        {receipt}
                    </div>
                </div>
            </div>
        );
    }
}

Pos.propTypes = {
    address: PropTypes.string,
    siteName: PropTypes.string,
    locale: PropTypes.string,
    receiptHeader: PropTypes.string,
    receiptFooter: PropTypes.string
};
