import React from 'react';
import ReactDOM from 'react-dom';
import Orders from "./Orders";

export default () => {
    const container = document.getElementById('supplier-orders');

    if (container) {
        const siteId = container.getAttribute('data-site-id');
        const orderId = container.getAttribute('data-order-id');

        ReactDOM.render(
            <Orders siteId={siteId} orderId={orderId}/>,
            container
        )
    }
};
