import React, {Component} from 'react';
import accounting from 'accounting';
import PropTypes from 'prop-types';

export default class MoneyInput extends Component {
    constructor(props) {
        super(props);

        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
    }

    handleFocus(e) {
        e.target.select();
    }

    handleBlur() {
        this.props.onChange(accounting.formatMoney(this.props.value));
    }

    handleChange(e) {
        this.props.onChange(e.target.value);
    }

    render() {
        return (
            <input
                id={this.props.id}
                type="text"
                className="form-control"
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                value={this.props.value}
                onFocus={this.handleFocus}
                ref={(input) => { this.input = input }}
            />
        );
    }
}

MoneyInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func
};
