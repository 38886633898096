import Pos from "./Pos";
import React from 'react';
import ReactDOM from 'react-dom';

export default () => {
    const container = document.getElementById('content');

    if (container) {
        const transactionNumberBase = parseInt(container.getAttribute('data-transaction-number-base'));

        ReactDOM.render(
            <Pos
                transactionNumberBase={transactionNumberBase ? transactionNumberBase + 1 : 0}
                dataUrl={container.getAttribute('data-pos-url')}
                submitUrl={container.getAttribute('data-submit-url')}
                siteName={container.getAttribute('data-site-name')}
                siteId={container.getAttribute('data-site-id')}
                locale={container.getAttribute('data-locale')}
                address={container.getAttribute('data-address')}
                taxNumber={container.getAttribute('data-tax-number')}
                cashier={container.getAttribute('data-cashier')}
                posStationName={container.getAttribute('data-pos-station-name')}
                posStationId={container.getAttribute('data-pos-station-id')}
                receiptHeader={container.getAttribute('data-receipt-header')}
                receiptFooter={container.getAttribute('data-receipt-footer')}
            />,
            container
        );
    }
};
