import React, {Component} from 'react';
import PaymentButtons from './PaymentButtons.jsx';
import MakePayment from './MakePayment.jsx';
import accounting from 'accounting';

export default class Payment extends Component {
    constructor(props) {
        super(props);
        this.handleCashChange = this.handleCashChange.bind(this);
        this.cancelPaymentType = this.cancelPaymentType.bind(this);
        this.handleSelectPay = this.handleSelectPay.bind(this);
    }

    handleCashChange(amount) {
        this.setState({amount: amount});
    }

    cancelPaymentType() {
        this.props.onSelectPay();
    }

    total() {
        if (this.props.method && this.props.method.moptypeid === "MOPTYPE_CASH") {
            return this.props.total.toDP(1);
        } else {
            return this.props.total;
        }
    }

    componentWillMount() {
        this.setState({amount: accounting.formatMoney(this.total())})
    }

    handleSelectPay(method) {
        if (method.moptypeid == "MOPTYPE_CASH") {
            this.setState({
                amount: accounting.formatMoney(this.props.total.toDP(1))
            });
        }

        this.props.onSelectPay(method);
    }

    render() {
        const method = this.props.method;

        if (method) {
            return (
                <MakePayment
                    contactToLocalDebtor={this.props.contactToLocalDebtor}
                    localDebtorCards={this.props.localDebtorCards}
                    amount={this.state.amount}
                    amountDue={this.total()}
                    onCashChange={this.handleCashChange}
                    onPayFinal={this.props.onPayFinal}
                    onCancel={this.cancelPaymentType}
                    method={method}
                />
            );
        } else {
            return (
                <PaymentButtons
                    mops={this.props.mops}
                    onRemovePayment={this.props.onRemovePayment}
                    previous={this.props.previous}
                    onSelectPay={this.handleSelectPay}
                    onCancel={this.props.onCancel}
                />
            );
        }
    }
}
