import React from "react";
import {Invoice} from "./Invoice";
import {useGetCustomerDataQuery} from "../../services/reconciliation";
import {createRoa, ReconciliationData} from "../../api/reconciliationAPI";


interface RProps {
    customerId: number;
    siteId: number;
}

export const Reconciliation = ({customerId, siteId}: RProps) => {
    // TODO need to do typings for this
    // @ts-ignore
    const {data, error, isLoading}:
        { data: ReconciliationData, error: string, isLoading: boolean }
        = useGetCustomerDataQuery({siteId: siteId, customerId: customerId})

    const onSubmit = async () => (
        await createRoa(customerId, siteId)
    );

    if (isLoading) {
        return <div>
            loading
        </div>
    }

    if (error || !data) {
        return <div>
            error
        </div>
    }

    return (
        <div>
            <button className="btn btn-primary" onClick={onSubmit}>ROA</button>
            {data.outstandingDebtorMops.map((invoice) =>
                <Invoice invoice={invoice} unallocatedROAs={data.unallocatedRoas}/>)
            }
        </div>
    )
}
