import React from "react";

interface LOProps {
    show: boolean
}

export const LoaderOverlay = ({show}: LOProps) => {
    if (!show) {
        return null;
    }

    return (
        <div style={{
            top: 0,
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            display: "block",
            backgroundColor: "black",
            zIndex: 1,
            opacity: 0.25,
        }}/>
    )
}