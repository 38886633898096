import React from "react";
import {ItemOrderRow} from "./ItemOrderRow";
import {ItemOrder, TaxRate} from "./OrderForm";

interface IOTProps {
    itemorders: ItemOrder[];
    editable: boolean;
    canSupply: boolean;
    taxRates: TaxRate[]
    onReceive: (itemOrderId: number) => void
    onDelete: (itemOrderId: number) => void
    onUpdateItemOrder: (itemOrderId: number, formData: object) => void
}

export const ItemOrderTable = ({itemorders, editable, canSupply, onReceive, onDelete, onUpdateItemOrder, taxRates}: IOTProps) => {
    return (
        <div>
            <table className="table table-responsive m-0">
                <thead>
                <tr>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th>Unit Cost</th>
                    {!editable ? <th>Supplied Quantity</th> : null}
                    <th>Tax Rate</th>
                    {/* TODO get currency */}
                    <th className="text-right">Total</th>
                    <th/>
                </tr>
                </thead>
                <tbody>

                {itemorders.map((itemOrder) => {
                    return (
                        <ItemOrderRow
                            taxRates={taxRates}
                            itemOrder={itemOrder}
                            editable={editable}
                            canSupply={canSupply}
                            onUpdate={onUpdateItemOrder}
                            onReceive={onReceive}
                            onDelete={onDelete}
                        />
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};