import ItemOrderForm from "./ItemOrderForm";
import Items from "./Items";
import {StockReceiveItemOrder} from "../common/stock-receive/StockReceiveWidget";
import {TaxInclusiveForm} from "./TaxInclusiveForm";
import {OrderDates} from "./OrderDates";
import {ItemOrderTable} from "./ItemOrderTable";
import ItemModal from "./ItemModal";
import {OrderTotal} from "./OrderTotal";
import React, {useState} from "react";
import {ReferenceForm} from "./ReferenceForm";
import {LoaderOverlay} from "../common/LoaderOverlay";
import {CreditNoteButton} from "./CreditNoteButton";
import {SupplyAllButton} from "./SupplyAllButton";
import DuplicateButton from "../features/order/DuplicateButton";

export interface TaxTotal {
    rate: string,
    total: number
}

export interface TaxRate {
    description: string;
    taxrateid: number;
}

export interface PurchaseOrder {
    emailLink?: string;
    pdfLink?: string;
    orderdate: string;
    requireddate: string;
    stateDescription: string;
    documentnumber: string;
    organisationName: string;
    orderRequiredDate?: string;
    orderOrderDate?: string;
    orderReceivedDate?: string;
    invoicetaxtype: string;
    canPlace: boolean;
    canReceive: boolean;
    canCredit: boolean;
    canSupply: boolean;
    cancellable: boolean;
    editable: boolean;
    isActive: boolean;
    taxDescription: string;
    taxTotal: number;
    subtotal: number;
    total: number;
    contactid: number;
    itemorders: ItemOrder[];
    taxTotalsByRate: TaxTotal[];
    validTaxRates: TaxRate[];
}

export interface ItemOrder {
    suppliedQuantity: number;
    itemName: string;
    unitcost: number;
    purchasetaxratedescription: string;
    total: number;
    itemorderid: number;
    tanks: object[];
    orderqty: number;
    taxRateId: number;
}

interface OFProps {
    refreshing: boolean;
    purchaseOrder: PurchaseOrder;
    selectedItemOrderId: number;
    setSelectedItemOrderId: (id: number | null) => void;
    onUpdateOrder: (formData: object) => void;
    onDeleteOrder: () => void;
    refreshOrder: () => void;
    onPlaceOrder: () => void;
    onCreditOrder: () => void;
    onReceiveOrder: () => void;
    onCancelOrder: () => void;
    onSubmitItemOrder: (formData: object) => void;
    onClear: () => void;
    setShowModal: (show: boolean) => void;
    onSelect: (a: object) => void;
    orderId: number;
    siteId: number;
    showModal: boolean;
    selectedItem: object;
    onDeleteItemOrder: (id: number) => void;
    onUpdateItemOrder: (id: number) => void;
}

export const OrderForm = (props: OFProps) => {
    const [show, setShow] = useState(false);

    const {
        onClear,
        selectedItem,
        orderId,
        siteId,
        onSelect,
        setShowModal,
        showModal,
        onUpdateOrder,
        onDeleteOrder,
        onPlaceOrder,
        onCreditOrder,
        onReceiveOrder,
        onCancelOrder,
        onSubmitItemOrder,
        onUpdateItemOrder,
        onDeleteItemOrder,
        selectedItemOrderId,
        setSelectedItemOrderId,
        refreshOrder,
        purchaseOrder,
        refreshing
    } = props;

    let itemOrder = purchaseOrder;
    const supplierId = itemOrder.contactid;

    let modalTitle, modalContent;
    // @ts-ignore
    if (selectedItem && selectedItem.value) {
        // @ts-ignore
        modalTitle = selectedItem.value.description;
        modalContent =
            <ItemOrderForm
                formData={{
                    // @ts-ignore
                    itemid: selectedItem.value.itemid,
                    supplierorderid: orderId,
                }}
                onSubmit={({formData}: { formData: object }) => {
                    onSubmitItemOrder(formData);
                }}
                onCancel={() => setShowModal(false)}
            />
    } else {
        modalTitle = 'Select Item';
        modalContent = <Items
            supplierId={supplierId}
            siteId={siteId}
            onSelect={(item: object) => {
                onSelect(item);
            }}/>
    }

    let stockReceiveItemOrderWidget = null;
    if (selectedItemOrderId) {
        stockReceiveItemOrderWidget = <StockReceiveItemOrder
            itemorderid={selectedItemOrderId}
            onClose={() => setSelectedItemOrderId(null)}
            onChange={() => {
                refreshOrder()
            }}
        />
    }

    const {editable, canPlace, canReceive, canCredit, itemorders, canSupply, cancellable} = itemOrder;



    const buttons = [];

    if (itemOrder.pdfLink) {
        buttons.push(<a className={"btn btn-primary"} href={itemOrder.emailLink}>Email Supplier</a>);

        buttons.push(<a className={"btn btn-primary"} href={itemOrder.pdfLink} target="_blank">Download PDF</a>);
    }

    if (canPlace) {
        buttons.push(<button className="btn btn-secondary" onClick={onPlaceOrder}>Place Order</button>);
    }

    if (canReceive) {
        buttons.push(<button className="btn btn-secondary" onClick={onReceiveOrder}>Finalise Order</button>);
    }

    if (canCredit) {
        buttons.push(<CreditNoteButton onSubmit={onCreditOrder} orderId={orderId}/>);
    }



    if (canSupply) {
        buttons.push(<SupplyAllButton onSubmit={refreshOrder} orderId={orderId}/>);
    }

    let cancelButton = null;

    if (editable) {
        cancelButton = <button onClick={onDeleteOrder} className="btn btn-danger">Delete</button>;
    } else if (cancellable) {
        cancelButton = <button onClick={onCancelOrder} className="btn btn-secondary">Cancel</button>;
    }

    // TODO search / adjustment error handling
    const onAdd = () => {
        onClear();
        setShowModal(true);
    };

    return (
        <div className="card">
            <LoaderOverlay show={refreshing}/>

            <div className="card-header d-flex justify-content-between">
                        <span>
                            {itemOrder.stateDescription}
                        </span>
                <div>
                    <DuplicateButton orderId={orderId}/>
                    {cancelButton}
                </div>
            </div>

            <div className="card-body">
                <h4>
                    {itemOrder.organisationName}
                </h4>

                <div className="d-flex flex-wrap mr-n1">
                    <div className="flex-grow-1 mr-2" style={{maxWidth: 330}}>
                        <ReferenceForm
                            onSubmit={(formData) => onUpdateOrder(formData)}
                            supplierOrder={itemOrder}
                        />
                    </div>

                    <div className="flex-grow-1 mr-2" style={{maxWidth: 330}}>
                        <TaxInclusiveForm
                            onSubmit={(formData) => onUpdateOrder(formData)}
                            supplierOrder={itemOrder}
                        />
                    </div>
                </div>

                <OrderDates order={itemOrder} onSubmit={onUpdateOrder}
                />
            </div>

            <ItemOrderTable
                taxRates={purchaseOrder.validTaxRates}
                canSupply={canSupply}
                editable={editable}
                itemorders={itemorders}
                onDelete={onDeleteItemOrder}
                onReceive={setSelectedItemOrderId}
                onUpdateItemOrder={onUpdateItemOrder}
            />

            {/*<ItemSearchInput siteId={siteId} supplierId={supplierId}/>*/}

            <div className="card-body border-top">
                <ItemModal
                    title={modalTitle}
                    show={showModal}
                    onClose={() => setShowModal(false)}
                >
                    {modalContent}
                </ItemModal>

                <div className="d-flex">
                    {
                        editable
                            ? <div>
                                <button className="btn btn-secondary" onClick={onAdd}>Add Item</button>
                            </div>
                            : null
                    }
                    <div className="ml-auto">
                        <OrderTotal
                            subtotal={itemOrder.subtotal}
                            taxDescription={itemOrder.taxDescription}
                            inclusive={itemOrder.invoicetaxtype === "TAXDISPLAY_INCLUSIVE"}
                            taxTotal={itemOrder.taxTotalsByRate}
                            total={itemOrder.total}
                        />
                    </div>
                </div>
            </div>

            {
                buttons.length !== 0
                    ? <div className="card-footer d-flex justify-content-end">
                        {buttons}
                    </div>
                    : null
            }

            {stockReceiveItemOrderWidget}
        </div>
    );
}
