import React, {Component} from 'react';

export default class MobileBar extends Component {
    render() {
        // TODO change to accounting.js
        let saleAction;
        const saleTotal = '$' + this.props.cartSummary.total;

        if (this.props.showMain) {
            let cartTotal;
            if (this.props.qty !== 0) {
                cartTotal = <span className="mobile-cart-total">{this.props.cartSummary.quantity}</span>;
            }
            saleAction =
                <a>
                    <span className="glyphicon glyphicon-shopping-cart"/>
                    {cartTotal}
                </a>;
        } else {
            saleAction = <a><span className="glyphicon glyphicon-plus"/></a>;
        }

        return (
            <div className="mobile-sale-bar">
                <ul className="mobile-sale-summary">
                    <li className="mobile-sale-action" onClick={this.props.onSwitch}>
                        {saleAction}
                    </li>
                    <li className="mobile-sale-total">
                        {saleTotal}
                    </li>
                </ul>
            </div>
        );
    }
}
