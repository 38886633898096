import axios from "../utils/axios";
import qs from "qs";

export interface Department {
    departmentid: number;
    description: string;
}

export interface Barcode {
    barcodeid: number;
    barcode: string;
}

interface StockLocation {

}

export interface Item {
    stocklocations: StockLocation[]
    itemid: number;
    stockonhand: number;
    description: string;
    unitprice: number;
    sku: string;
    barcodes: Barcode[]
}

export async function getDepartmentList(siteId: number) {
    let url = `/itemdrystock/${siteId}/departments`;

    const {data} = await axios.get<Department[]>(url);
    return data
}

export async function getSearchItem(siteId: number, search?: any) {
    let url = `/itemdrystock/${siteId}`;

    const params = qs.stringify(search)

    console.log(search, params)

    if (search) {
        url += `/search?${params}`
    }

    const {data} = await axios.get<Item[]>(url);
    return data
}

export async function makeAdjustment(siteId: number, data: any) {
    let url = `/itemdrystock/${siteId}/adjustment`;

    await axios.post(url, data);
}
// url: `/itemdrystock/${siteId}/search?q=${encodeURIComponent(search)}`,
