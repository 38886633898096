import React, {Component} from 'react';
import ModalContent from "./ModalContent";
import ModalHeader from "./ModalHeader";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";

export default class PaymentButtons extends Component {
    constructor(props) {
        super(props);
        this.handlePayClick = this.handlePayClick.bind(this);
    }

    handlePayClick(mop) {
        this.props.onSelectPay(mop);
    }

    render() {
        // TODO test postMessage MOP
        let mops = this.props.mops;

        mops = mops.map((mop) => {
            return (
                <button
                    key={mop.moptypeid}
                    onClick={this.handlePayClick.bind(this, mop)}
                    type="button"
                    data-payment={mop.description}
                    className="btn btn-pos btn-pos-menu"
                >
                    {mop.description}
                </button>
            );
        });

        return (
            <ModalContent>
                <ModalHeader>Select Payment Type</ModalHeader>
                <ModalBody>
                    {mops}
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-default btn-block" onClick={this.props.onCancel}>Cancel</button>
                </ModalFooter>
            </ModalContent>
        );
    }
}
