import {Modal} from "react-bootstrap";
import PropTypes from "prop-types";
import React, {PureComponent} from "react";

export default class ItemModal extends PureComponent {
    render() {
        const {show, onClose, title} = this.props;

        return (
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}

                </Modal.Body>
            </Modal>
        );
    }
}

ItemModal.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};
