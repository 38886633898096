import React, {Component} from "react";

export default class ModalHeader extends Component {
    render() {
        return (
            <div className="pos-modal-header">
                <h3>
                    {this.props.children}
                </h3>
            </div>
        );
    }
}