import { combineReducers } from '@reduxjs/toolkit'

import reportReducer from "../features/report/reportSlice";
import stocktakeReducer from "../features/stocktake/stocktakeSlice";
import orderReducer from "../features/order/orderSlice";
import {reconciliationApi} from "../services/reconciliation";

const rootReducer = combineReducers({
    report: reportReducer,
    stocktake: stocktakeReducer,
    order: orderReducer,
    [reconciliationApi.reducerPath]: reconciliationApi.reducer
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
