import React, {Component} from 'react';
// import ReactCSSTransitionGroup from 'react/lib/ReactCSSTransitionGroup';
import accounting from 'accounting';

export default class ReceiptSummary extends Component {
    render() {
        let total = this.props.total,
            amountDue = this.props.amountDue,
            rounding = 0, change = 0;
        const lastSale = this.props.lastSale;

        if (!this.props.saleActive) {
            change = lastSale.change;
            rounding = lastSale.rounding;
            amountDue = 0;
            total = lastSale.saleTotal;
        }

        return (
            <div>
                <div style={{display: "inline-block", width: "100%"}} className="receipt-summary clearfix">
                    <table className="table-fixed invoice-summary pull-right hidden-xs">
                        <tbody>
                        <tr className="invoice-total">
                            <td>TOTAL</td>
                            {/*<ReactCSSTransitionGroup*/}
                            {/*    transitionName={'fade'}*/}
                            {/*    transitionEnterTimeout={150}*/}
                            {/*    transitionLeave={false}*/}
                            {/*    component='td'*/}
                            {/*>*/}
                                <span key={total}>{accounting.formatMoney(total)}</span>
                            {/*</ReactCSSTransitionGroup>*/}
                        </tr>
                        <tr>
                            <td width="50%">Amount Due:</td>
                            <td width="50%">{accounting.formatMoney(amountDue)}</td>
                        </tr>
                        <tr>
                            <td width="50%">Change:</td>
                            <td width="50%">{accounting.formatMoney(change)}</td>
                        </tr>
                        <tr>
                            <td width="50%">Rounding:</td>
                            <td width="50%">{accounting.formatMoney(rounding)}</td>
                        </tr>
                        </tbody>
                    </table>
                    <table className="table-fixed invoice-summary pull-right visible-xs">
                        <thead>
                        <tr>
                            <th>Amount Due:</th>
                            <th>Total:</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{accounting.formatMoney(amountDue)}</td>
                            <td className="total">{accounting.formatMoney(total)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
