import React, {Component} from "react";

export default class ModalBody extends Component {
    render() {
        return (
            <div className="pos-modal-body">
                {this.props.children}
            </div>
        );
    }
};
