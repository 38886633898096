import React, {useState} from 'react';
import {Tank} from "../../order-wetstock/OrderWetstock";
import {WetstockFormExisitngOrder} from "../../order-wetstock/WetstockForm";
import {ModalBody, ModalFooter} from "react-bootstrap";

interface TSFProps {
    tanks: Tank[];
    onSubmit: () => void;
    onCancel: () => void;
    itemOrderId: number;
    unsuppliedQuantity: number;
    gradeName: string;
    formId: string;
}

export const TankDropSelectModalContent = ({tanks, onSubmit, itemOrderId, unsuppliedQuantity, onCancel, gradeName, formId}: TSFProps) => {
    const [selectedTank, setSelectedTank] = useState<number>();

    let currentTank: Tank | undefined;
    if (selectedTank) {
        tanks.forEach((tank) => {
            if (tank.storagetankid === selectedTank) {
                currentTank = tank;
            }
        })
    }


    if (currentTank) {
        return (

            <WetstockFormExisitngOrder
                title={currentTank.name}
                asModalContent
                itemOrderId={itemOrderId}
                tank={currentTank}
                onSubmit={onSubmit}
                onCancel={onCancel}
                id={formId}
            />
        );
    }

    return (

        <React.Fragment>

            <ModalBody>
                <p>Select a tank to drop <strong>{gradeName}</strong> into</p>
            </ModalBody>
            <ul className="list-group list-group-flush border-top">
                {tanks.map((tank, i) => {
                    return (
                        <li className={"list-group-item"}>
                            <h5>{tank.name}</h5>
                            <button className="btn btn-primary"
                                    onClick={() => setSelectedTank(tank.storagetankid)}>Select
                            </button>
                        </li>
                    )
                })}
            </ul>
            <div className="modal-footer border-top-0" >
                <button className="btn btn-secondary" onClick={onCancel}>Cancel</button>
            </div>
        </React.Fragment>

    )
}
