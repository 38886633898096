import React from "react";
import {Field, Form, FormSpy} from "react-final-form";
import {Supplier, Tank} from "./OrderWetstock";
import DateTime from "react-datetime";
import {dropNewOrder} from "../api/tankAPI";
import {ModalBody, ModalFooter} from "react-bootstrap";

//TODO bootstrap / yii error components of this should be turned into reusable components
interface WFBaseProps {
    onSubmit: () => any;
    onCancel?: () => any;
    id: string;
    asModalContent?: boolean;
    title?: string;
}

interface WFProps extends WFBaseProps {
    suppliers?: Supplier[];
    initialValues: object;

}

interface WFNEBaseProps extends WFBaseProps {
    tank: Tank;
}

interface WFNewProps extends WFNEBaseProps {
    suppliers: Supplier[];
}

interface WFExistingProps extends WFNEBaseProps {
    itemOrderId: number;
}

interface Error {
    field: string;
    message: string;
}


export const WetstockFormNewOrder = ({tank, ...rest}: WFNewProps) => {
    return <WetstockForm {...rest} initialValues={{
        storageTankId: tank.storagetankid
    }}/>
}

export const WetstockFormExisitngOrder = ({itemOrderId, tank, ...rest}: WFExistingProps) => {
    return <WetstockForm {...rest} initialValues={{
        storageTankId: tank.storagetankid,
        itemOrderId: itemOrderId
    }}/>
}

const WetstockForm = ({onCancel, onSubmit, suppliers, initialValues, id, title, asModalContent}: WFProps) => {
    const onSubmitForm = async (data: object) => {
        let errors: { [key: string]: string } = {};

        await dropNewOrder(data).then((e) => {

            onSubmit();


            //    TODO dispatch fetchTankData
        }).catch((reason) => {
            if (reason.response) {
                if (reason.response.status === 422) {
                    const data: Error[] = reason.response.data;

                    data.forEach((error) => {
                        errors[error.field] = error.message;
                    })
                }
                //    TODO other errors
            }
        });


        return errors
    };

    return (

        <Form
            onSubmit={onSubmitForm}
            initialValues={initialValues}
            render={({handleSubmit, submitting, pristine, invalid, values, errors}) => {

                let header = title ? <h4>{title}</h4> : null

                const form = <form id={id} onSubmit={handleSubmit}>

                    {header}

                    <Field name="dateTime">
                        {({input, meta}) => (
                            <div className="form-group">
                                <label>Date</label>

                                <DateTime
                                    onChange={(dateTime) => {
                                        if (!(typeof dateTime === 'string')) {
                                            input.onChange(dateTime.format("YYYY-MM-DD HH:mm:ss"));
                                        }
                                    }}
                                    input={false}
                                />
                                <small id="dateTimeHelp" className="form-text text-muted">Select the date and time
                                    the fuel drop
                                    occurred.</small>
                                {/* TODO bit of a hack to get invalid-feedback to show - could also set element style to display: block*/}

                                {meta.submitError && <div className="is-invalid"/>}
                                <div className="invalid-feedback">
                                    {meta.submitError}
                                </div>
                            </div>
                        )}
                    </Field>

                    <Field
                        type="hidden"
                        id="storageTankIdInput"
                        component="input"
                        className="form-control"
                        name="storageTankId"
                    />

                    {/*       TODO create render component */}

                    <Field id="volumeIdInput" name="volume">
                        {({input, meta}) => (

                            <div className="form-group">
                                <label htmlFor={input.id}>Volume</label>

                                <input
                                    {...input}
                                    type="number"
                                    className={meta.submitError ? "form-control is-invalid" : "form-control"}
                                />

                                <div className="invalid-feedback">
                                    {meta.submitError}
                                </div>
                            </div>
                        )}
                    </Field>

                    <Field id="unitCostInput" name="unitCost">
                        {({input, meta}) => (

                            <div className="form-group">
                                <label htmlFor={input.id}>Unit Cost</label>

                                <input
                                    {...input}
                                    type="number"
                                    className={meta.submitError ? "form-control is-invalid" : "form-control"}
                                />

                                <div className="invalid-feedback">
                                    {meta.submitError}
                                </div>
                            </div>
                        )}
                    </Field>

                    {suppliers ?
                        <Field id="supplierIdInput" name="supplierId">
                            {({input, meta}) => (

                                <div className="form-group">
                                    <label htmlFor={input.id}>Fuel Supplier</label>

                                    <select {...input}
                                            className={meta.submitError ? "form-control is-invalid" : "form-control"}>
                                        <option>
                                            {/* TODO shouldn't really have a non answer default*/}
                                            Select a supplier
                                        </option>
                                        {suppliers.map((supplier) => {
                                            return (
                                                <option value={supplier.contactid}>
                                                    {supplier.organisationname}
                                                </option>
                                            )
                                        })}
                                    </select>

                                    <div className="invalid-feedback">
                                        {meta.submitError}
                                    </div>
                                </div>
                            )}
                        </Field>
                        : null}

                    {!asModalContent ?

                        <div className="buttons">

                            {
                                onCancel ?
                                    <button onClick={onCancel} type="button" className="btn btn-secondary">
                                        Cancel
                                    </button>
                                    : null
                            }

                            <button type="submit" className="btn btn-primary" onClick={handleSubmit} disabled={submitting}>
                                Submit
                            </button>
                        </div>
                        : null
                    }
                </form>


                if (asModalContent) {
                    return (
                        <React.Fragment>
                            <ModalBody>
                                {form}
                            </ModalBody>
                            <ModalFooter>
                                { onCancel ?
                                <button onClick={onCancel} type="button" className="btn btn-secondary">
                                    Cancel
                                </button>
                                    : null
                                }

                                {/*<FormSpy>*/}
                                {/*    {(form) => (*/}
                                        <button onClick={handleSubmit} type="submit" className="btn btn-primary" disabled={submitting}>
                                            Submit
                                        </button>
                                    {/*)}*/}
                                {/*</FormSpy>*/}

                            </ModalFooter>
                        </React.Fragment>
                    )
                } else {
                    return form;
                }
            }}
        />
    );
}

export default WetstockForm;
