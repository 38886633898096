import React, {Component} from 'react';

export default class Clock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: this.getTime()
        };
    }

    getTime() {
        const date = new Date();
        if (this.props.date) {
            return date.toLocaleString(this.props.locale);
        } else {
            return date.toLocaleTimeString(this.props.locale);
        }
    }

    componentWillMount() {
        this.intervals = [];
    }

    componentWillUnmount() {
        this.intervals.map(clearInterval);
    }

    componentDidMount() {
        this.intervals.push(setInterval(function () {
            this.setState({time: this.getTime()});
        }.bind(this), 500));
    }

    render() {
        return <span className="clock">{ this.state.time }</span>
    }
}
