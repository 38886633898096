import {createRoa, CustomerInvoice, UnallocatedROA} from "../../api/reconciliationAPI";
import React, {useState} from "react";
import {useGetInvoiceDataQuery} from "../../services/reconciliation";
import {SaleDisplay} from "../../common/sale/Sale";


interface IProps {
    invoice: CustomerInvoice
    unallocatedROAs: UnallocatedROA[]
}

const InvoiceDetails = ({invoice, unallocatedROAs}: IProps) => {
    // TODO need to do typings for this
    // @ts-ignore
    const {data, error, isLoading}:
        { data: any, error: string, isLoading: boolean }
        = useGetInvoiceDataQuery(invoice.salemopid)


    return <div>
        {/*<h3>{invoice.transactionnumber}</h3>*/}

        <SaleDisplay sale={invoice.sale}/>



        <div>
            {/*{invoice.datetime}*/}
        </div>


        <div>
            {invoice.tender}
        </div>

    </div>;
}

const RoaSelector = ({invoice, unallocatedROAs}: IProps) => {
    // sort unallocated ROAs by their closeness to the due amount in the invoice
    console.log(unallocatedROAs)
    const sortedROAs = [...unallocatedROAs].sort((unallocatedROA) => {
        return Math.abs(invoice.due - unallocatedROA.unallocated);
    })

    return (
        <div>
            <div>
                Amount Due: {invoice.due}
            </div>
            <div>r
                Select ROA:
                {sortedROAs.map(function (unallocatedROA)  {
                    return <div>
                        {/* TODO show details of ROA */}
                        {unallocatedROA.unallocated} <button className="btn btn-primary">Allocated</button>
                    </div>
                })}
            </div>

        </div>
    )
}

export const Invoice = ({invoice, unallocatedROAs}: IProps) => {
    const [show, setShow] = useState(false);

    return (
        <div>
            {invoice.saleid}
            {/*{invoice.transactionnumber}*/}
            <button onClick={() => setShow(!show)}>Expand</button>



            {show ?
            <div>
                <InvoiceDetails unallocatedROAs={unallocatedROAs} invoice={invoice}/>
                <RoaSelector invoice={invoice} unallocatedROAs={unallocatedROAs}/>
            </div>

                : null}
        </div>
    )
}