import React, {PureComponent} from 'react';

export default class ErrorFlash extends PureComponent {
    render() {
        // TODO dismissable
        return (
            <div className="alert alert-danger" role="alert">
                {this.props.children}
            </div>
        );
    }
}
