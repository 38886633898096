//TODO this is taken directly from zev - should have common components in their own repo
import Form from "react-jsonschema-form";
import React from "react";

// HOC for passing props to react-jsonschema-form
export const withErrorProps = Component => ({errors, ...rest}) => {
    const template = (props) => {
        const {id, classNames, label, help, required, description, children, hidden, displayLabel} = props;

        let errorMessage;

        let fieldComponent = children;

        if (Array.isArray(fieldComponent)) {
            fieldComponent = children[0];
        }


        if (fieldComponent.props) {
            const name = fieldComponent.props.name;

            for (let i = 0; i < errors.length; i++) {
                const error = errors[i];
                if (error.field === name) {
                    errorMessage = error.message;
                }
            }
        }

        // react-jsonform-schema uses the template for the root for some reason
        if (id === "root" || hidden) {
            return <div>{children}</div>
        }

        // TODO renders label when not needed such as checkbox
        return (
            <div className={classNames}>
                <label className={displayLabel ? undefined : 'hidden'} htmlFor={id}>
                    {label}{required ? "*" : null}
                </label>
                {description}
                {children}
                <div className="help-block">
                    <span className="text-danger">{errorMessage}</span>
                </div>
                {help}
            </div>
        );
    };

    // use time as key to force re-render
    return <Component key={Date.now()} noValidate FieldTemplate={template} {...rest}/>
};

export default withErrorProps(Form);