import React, {Component, PureComponent} from "react";
import PropTypes from "prop-types";
import Form from "react-jsonschema-form";
import connect from "../connect";
import {Modal} from "react-bootstrap";

class PriceForm extends Component {
    render() {
        const {onSubmit, formData, onCancel} = this.props;
        return (
            <Form
                noHtml5Validate
                schema={{
                    type: "object",
                    required: ["unitprice"],
                    properties: {
                        unitprice: {type: "number", title: 'Price'},
                    }
                }}
                uiSchema={{
                    "unitprice": {
                        "ui:autofocus": true
                    }
                }}
                onSubmit={({formData}) => onSubmit(formData)}
                formData={formData}
            >
                <div className="clearfix">
                    <div className="btn-toolbar pull-right">
                        <button type="submit" className="btn btn-primary">Yes</button>
                        <button type="button" className="btn btn-secondary" onClick={onCancel}>No</button>
                    </div>
                </div>
            </Form>
        );
    }
}

export class ReceiveStockPriceAdjust extends PureComponent {
    render() {
        const {unitPrice, itemFetch} = this.props;

        if (itemFetch.pending) {
            return <div>loading</div>
        }
        if (itemFetch.rejected) {
            return <div>rejected</div>
        }


        const item = itemFetch.value;

        const targetUnitPrice = item.marginData.target;
        const text = item.marginData.text;

        const {unitprice} = itemFetch.value;
        console.log(item);


        return (
            <React.Fragment>
                <Modal.Header closeButton>
                    <Modal.Title>{item.description}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>The current price is {unitprice}.</div>

                        <div>{text}</div>

                        <div>Do you want to change the unit price?</div>
                        {/*TODO check existence of margin data */}

                        <PriceForm
                            onCancel={this.props.onCancel}
                            onSubmit={(values) => this.props.postPrice(values, item.itemid, item.pluOuterChild)}
                            formData={{unitprice: targetUnitPrice}}
                        />
                    </div>
                </Modal.Body>
            </React.Fragment>
        )
    }
}

//TODO switch on marginData
//  Margin data should be general price adjustment based on either dep margin or *

const ReceiveStockPriceAdjustWithFetch = connect((props) => {
    const {itemId} = props;

    const itemFetch = (id) => {
        return {
            url: `/itemdrystock/view/${id}`,
            comparison: `${id}`,
            then: (value) => {
                if (!value.marginData) {
                    props.onCancel();
                    props.onAdjusted();
                }
            }
        }
    };

    return {
        itemFetch: itemFetch(itemId),
        postPrice: (formData, id, pluOuterChild) => {
            return {
                postPriceResponse: {
                    method: 'PUT',
                    url: `/itemdrystock/ajax-update/${id}`,
                    body: JSON.stringify(formData),
                    andThen: (value) => {
                        if (pluOuterChild) {
                            return {
                                itemFetch: itemFetch(pluOuterChild.pluinnerid)
                            };
                        } else {
                            props.onAdjusted();
                        }

                        return {};
                    },
                }
            }
        },
    }
})(ReceiveStockPriceAdjust);

ReceiveStockPriceAdjustWithFetch.propTypes = {
    itemId: PropTypes.number.isRequired,
    onAdjusted: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

ReceiveStockPriceAdjust.propTypes = {
    unitPrice: PropTypes.number.isRequired,
    costPrice: PropTypes.number.isRequired,
    targetMargin: PropTypes.number.isRequired,
    targetUnitPrice: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default ReceiveStockPriceAdjustWithFetch;
