import React, {Component} from "react";
import PosMobileProductButton from "./PosMobileProductButton.jsx";

export default class PosMobileProducts extends Component {
    render() {
        let questions, required;

        const buttonNodes = this.props.data.map(function (product) {
            required = false;
            questions = product.questions;
            if (questions) {
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].required) {
                        required = true;
                        break;
                    }
                }
            }

            return (
                <PosMobileProductButton onAddItem={this.props.onAddItem} required={required} key={product.id}
                                        id={product.id}>
                    {product.name}
                </PosMobileProductButton>
            );
        }.bind(this));

        return (
            <div className="PosProducts">
                <ul className='mobile-product-buttons'>
                    {buttonNodes}
                </ul>
            </div>
        );
    }
}
