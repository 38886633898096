import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import ItemOrderReceive from "./ItemOrderReceive";

export const withModal = (Component) => (props) => {
    const {onClose} = props;

    const [showModal, setModal] = useState(true);

    // const closeModal = () => setModal(false);

    function closeModal() {
        setModal(false);
    }

    return (
        <Modal
            backdrop='static'
            onExited={onClose}
            show={showModal}
            onHide={closeModal}
        >
            <Component {...props} closeModal={closeModal}/>
        </Modal>
    );
};

export const StockReceiveItem = () => {
};

export const StockReceiveItemOrder = withModal(ItemOrderReceive);
