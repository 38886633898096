import React, {Component} from 'react';

export default class QuickButtons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            buttonsPage: 0
        };
    }

    handleClick(itemButton) {
        if (typeof itemButton.itembuttonid !== 'undefined') {
            this.props.onButtonItemClick(itemButton.itembuttonid);
        } else {
            this.props.onDepartmentButtonClick(itemButton.departmentid);
        }
    }

    render() {
        const pageSize = 5;

        const buttons = this.props.itemButtons.map((itemButtonsRow) => {
            return itemButtonsRow.slice(pageSize * this.state.buttonsPage, pageSize).map((itemButton) => {
                const width = (100.0 / pageSize) + "%";
                if (itemButton) {
                    return <div style={{width: width}} className="pos-btn-wrap">
                        <button onClick={this.handleClick.bind(this, itemButton)}
                                className="btn btn-pos">{itemButton.label}</button>
                    </div>;
                } else {
                    return <div style={{width: width}} className="pos-btn-wrap">
                    </div>;
                }
            });
        });

        return (
            <div className="pos-btn-container clearfix">
                {buttons}
            </div>
        );
    }
}
