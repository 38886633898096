import React, {Component} from 'react';
import accounting from 'accounting';
// import ReactCSSTransitionGroup from 'react/lib/ReactCSSTransitionGroup';

export default class InvoiceItem extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.onSelectItem(this.props.index);
    }

    render() {
        // item is the invoice item - ie the object on the invoice
        // product is the product object
        let item = this.props.item,
            product = item.product,
            unitPrice = item.unitPriceOverride || product.price,
            salePrice = unitPrice.mul(item.qty),
            className = this.props.selected ? "invoiceItem active" : "invoiceItem";

        return (
            <div>
                {/*<ReactCSSTransitionGroup*/}
                {/*    transitionName="fade"*/}
                {/*    transitionAppear={true}*/}
                {/*    transitionAppearTimeout={150}*/}
                {/*    transitionEnter={false}*/}
                {/*    transitionLeave={false}*/}
                {/*    onClick={this.handleClick}*/}
                {/*    className={className}*/}
                {/*    component='tr'*/}
                {/*    key={product.name}*/}
                {/*>*/}
                <td>{product.name}</td>
                {/*<ReactCSSTransitionGroup*/}
                {/*    transitionName={'fade'}*/}
                {/*    transitionEnterTimeout={150}*/}
                {/*    transitionLeave={false}*/}
                {/*    component='td'*/}
                {/*>*/}
                <span key={item.qty}>{item.qty}</span>
                {/*</ReactCSSTransitionGroup>*/}
                <td>{accounting.formatMoney(unitPrice)}</td>
                {/*<ReactCSSTransitionGroup*/}
                {/*    transitionName={'fade'}*/}
                {/*    transitionEnterTimeout={150}*/}
                {/*    transitionLeave={false}*/}
                {/*    component='td'*/}
                {/*>*/}
                <span key={salePrice}>{accounting.formatMoney(salePrice)}</span>
                {/*</ReactCSSTransitionGroup>*/}
                {/*</ReactCSSTransitionGroup>*/}
            </div>
        );
    }
}
