import React, {Component} from 'react';
import fuzzy from 'fuzzy';
import accounting from 'accounting';
import Search from './Search.jsx';

export default class LocalDebtorCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ''
        };

        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.handleSearch('');
    }

    handleSearch(term) {
        this.setState({
            searchTerm: term
        });

        term = term.trim();

        const options = {
            extract: (card) => card.cardnumber + card.cardholdername,
        };

        const contactToLocalDebtor = this.props.contactToLocalDebtor;

        const items = fuzzy.filter(term, this.props.localDebtorCards, options).map((card) => {
            const originalCard = card.original,
                localDebtor = contactToLocalDebtor[originalCard.contactid];
            return {
                organisationname: localDebtor.organisationname,
                id: originalCard.contactid,
                balance: localDebtor.accountbalance,
                creditLimit: localDebtor.creditlimit,
                postalAddress: localDebtor.postaladdress,
                cardHolderName: originalCard.cardholdername,
                cardNumber: originalCard.cardnumber
            }
        });

        this.setState({
            searchedLocalDebtors: items
        });
    }

    handleClick(customer) {
        if (typeof this.props.onLocalDebtorSelect === "function") {
            this.props.onLocalDebtorSelect(customer);
        }
    }

    render() {
        let localDebtors;
        if (this.state.searchedLocalDebtors) {
            localDebtors = this.state.searchedLocalDebtors.map((customer) => {
                return (
                    <div key={customer.id} onClick={this.handleClick.bind(this, customer)}>
                        <div style={{borderBottom: '1px solid black', padding: 5}}>
                            <p style={{fontWeight: "bold"}}>
                                <span >{customer.organisationname}</span>
                                <span className="pull-right"># {customer.cardNumber}</span>
                            </p>
                            <p style={{wordBreak: "break-word", display: "none"}}>
                                Address<br/>{customer.postalAddress}</p>

                            <table style={{width: "100%"}}>
                                <tbody>
                                <tr>
                                    <td>Balance</td>
                                    <td style={{textAlign: "right"}}>{accounting.formatMoney(customer.balance)}</td>
                                </tr>
                                <tr>
                                    <td>Credit Limit</td>
                                    <td style={{textAlign: "right"}}>{accounting.formatMoney(customer.creditLimit)}</td>
                                </tr>
                                {/*<tr>*/}
                                {/*<td>Cardholder Name</td>*/}
                                {/*<td style={{textAlign: "right"}}>{customer.cardHolderName}</td>*/}
                                {/*</tr>*/}

                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            });
        }

        return (
            <div>
                <Search onSearch={this.handleSearch} searchTerm={this.state.searchTerm}/>
                <div style={{
                    maxHeight: 400,
                    overflowX: 'hidden',
                    overflowY: 'scroll'
                }}>
                    {localDebtors}
                </div>
            </div>
        );
    }
}
