import React, {Component} from "react";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import PropTypes from 'prop-types';

export default class ModalForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.onSubmit();
    }

    render() {
        let header;
        if (this.props.title) {
            header = <ModalHeader>
                {this.props.title}
            </ModalHeader>;
        }

        let cancelButton;
        if (this.props.onCancel) {
            cancelButton =
                <button onClick={this.props.onCancel} className="btn btn-default">
                    {this.props.cancelText}
                </button>;
        }

        return (
            <ModalContent>
                {header}
                <form onSubmit={this.handleSubmit}>
                    <ModalBody>
                        {this.props.children}
                    </ModalBody>
                    <ModalFooter>
                        <div className="clearfix">
                            <div className="btn-toolbar pull-right">
                                <button type="submit" className="btn btn-success">{this.props.submitText}</button>
                                {cancelButton}
                            </div>
                        </div>
                    </ModalFooter>
                </form>
            </ModalContent>
        );
    }
}

ModalForm.defaultProps = {
    submitText: "Ok",
    cancelText: "Cancel",
};

ModalForm.propTypes = {
    title: PropTypes.string,
    submitText: PropTypes.string,
    cancelText: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
};
