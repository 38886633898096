import React, {Component} from "react";
import fuzzy from "fuzzy";
import accounting from "accounting";
import Search from "./Search.jsx";

export default class LocalDebtors extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ''
        };

        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount() {
        this.handleSearch('');
    }

    handleSearch(term) {
        this.setState({
            searchTerm: term
        });

        term = term.trim();

        const options = {
            extract: (customer) => customer.organisationname,
        };

        const items = fuzzy.filter(term, this.props.localDebtors, options).map((customer) => {
            return {
                organisationname: customer.original.organisationname,
                id: customer.original.contactid,
                balance: customer.original.accountbalance,
                creditLimit: customer.original.creditlimit,
                postalAddress: customer.original.postaladdress,
            }
        });

        this.setState({
            searchedLocalDebtors: items
        });
    }

    handleClick(customer) {
        if (typeof this.props.onLocalDebtorSelect === "function") {
            this.props.onLocalDebtorSelect(customer);
        }
    }

    render() {
        let localDebtors;
        if (this.state.searchedLocalDebtors) {
            localDebtors = this.state.searchedLocalDebtors.map((customer) => {
                return <div className="action-card" key={customer.id} onClick={this.handleClick.bind(this, customer)}>
                    <div className="customer-card">
                        <div className="row">
                            <div className="col-xs-6">
                                <p>{customer.organisationname}</p>
                            </div>

                            <div className="col-xs-3">
                                <span className="pull-right">{accounting.formatMoney(customer.balance)}</span>
                            </div>

                            <div className="col-xs-3">
                                <span className="pull-right">{accounting.formatMoney(customer.creditLimit)}</span>
                            </div>
                        </div>
                    </div>
                </div>;
            });
        }

        return (

            <div style={{height: '100%'}}>
                <Search onSearch={this.handleSearch} searchTerm={this.state.searchTerm}/>
                <div style={{padding: "0 10px 20px", fontWeight: 'bold', fontSize: '125%', maxHeight: '45px', overflow: 'hidden'}}>
                    <div className="row">
                        <div className="col-xs-6">Organisation</div>
                        <div className="col-xs-3">
                            <span className="pull-right">Balance</span>
                        </div>

                        <div className="col-xs-3">
                            <span className="pull-right">Credit Limit</span>
                        </div>
                    </div>
                </div>
                <div className="action-card-container pos-card-control"
                     style={{height: 'calc(100% - 94px)'}}>{localDebtors}</div>
            </div>
        );
    }
}
