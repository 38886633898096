import React, {Component} from "react";
import ReceiptSummary from "./ReceiptSummary.jsx";
import InvoiceItem from "./InvoiceItem.jsx";
import accounting from "accounting";

export default class PosSale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartPageOffset: 0,
            shiftActionsShown: false
        };
    }

    findMop(mopid) {
        let mop;
        const mops = this.props.mops;
        for (let i = 0; i < mops.length; i++) {
            mop = mops[i];
            if (mop.mopid === mopid) {
                return mop;
            }
        }
    }

    onRemovePayment(index) {
        this.props.onRemovePayment(index);
    }

    render() {
        const cart = this.props.cart;
        const items = cart.map((item, index) => {
            return (
                <InvoiceItem
                    selected={this.props.selectedSale === index}
                    index={index}
                    key={index}
                    item={item}
                    onSelectItem={this.props.onSelectItem}
                />
            );
        });

        let receiptContents;
        if (items.length) {
            receiptContents =
                <table className="table-fixed invoice">
                    <thead>
                    <tr>
                        <th width="36%">Name</th>
                        <th width="14%">Qty</th>
                        <th width="25%">Unit Price</th>
                        <th width="25%">Amount</th>
                    </tr>
                    </thead>
                    <tbody>{items}</tbody>
                </table>
        } else {
            receiptContents = <h4 className="receipt-placeholder">Add an item to start...</h4>
        }

        const cartSummary = this.props.cartSummary;

        let saleActon;
        if (this.props.saleActive) {
            saleActon = <button
                disabled={!cartSummary.quantity} type="button"
                className="btn btn-danger"
                onClick={this.props.onReset}>Reset Sale
            </button>
        } else {
            saleActon = <button
                type="button"
                className="btn btn-default"
                onClick={this.props.onNewSale}>New Sale
            </button>
        }

        const invoiceActions =
            <div className="receipt-actions clearfix">
                <div className="btn-toolbar pull-left">
                    <button disabled={cartSummary.quantity && this.props.saleActive} type="button"
                            className="btn btn-default"
                            onClick={window.print}>Print Receipt
                    </button>
                </div>

                <div className="btn-toolbar pull-right">
                    {saleActon}
                </div>
            </div>;

        const previousPayments = this.props.payments.map((item, index) => {
            let mopName = this.findMop(item.method).description;
            // if the payment has a card number associated with it handle it as an account payment
            if (item.organisationName) {
                mopName = 'Account ' + item.organisationName;
            }

            return (
                <div style={{fontSize: "120%"}} className="clearfix" key={index}>
                    <div className="pull-right">
                        {mopName + ': ' + accounting.formatMoney(item.amount)}
                        &nbsp;
                        {this.props.saleActive ?
                            <a href="javascript:void(0)" onClick={this.onRemovePayment.bind(this, index)}>
                                <span className="glyphicon glyphicon-remove text-danger"/>
                            </a> : ''}
                    </div>
                </div>
            );
        });

        return (
            <div className={this.props.saleActive ? "receipt-container" : "receipt-container sale-finalized"}>
                <div className="receipt-body">
                    {receiptContents}

                    {previousPayments.length ?
                        <div className="pull-right">
                            <div className="clearfix">
                                <h4 className="pull-right">Payments</h4>
                            </div>
                            {previousPayments}
                        </div> : ''}
                </div>
                <div className="receipt-footer">
                    <div className="row">
                        <div className="col-xs-5">
                            <button disabled={!(cartSummary.quantity && this.props.saleActive)} type="button"
                                    className="btn btn-default"
                                    onClick={this.props.onPay}
                                    style={{
                                        height: 80,
                                        width: "100%",
                                        fontWeight: "bold",
                                        fontSize: "200%",
                                        color: "#9ADB7F"
                                    }}
                            >PAY
                            </button>
                        </div>
                        <div className="col-xs-7">
                            <ReceiptSummary
                                saleActive={this.props.saleActive}
                                lastSale={this.props.lastSale}
                                amountDue={this.props.amountDue}
                                total={cartSummary.total}
                            />
                        </div>
                    </div>
                </div>
                {invoiceActions}
            </div>
        );
    }
}
