import React, {Component} from "react";
import Search from "./Search.jsx";
import fuzzy from "fuzzy";
import accounting from "accounting";

export default class Products extends Component {
    constructor(props) {

        super(props);
        this.state = {
            searchResults: [],
            searchTerm: '',
        };

        this.handleSearch = this.handleSearch.bind(this);
    }

    handleSearchItemClick(itemId) {
        this.props.onAddItem(itemId);
    }

    handleSearch(term) {
        this.setState({
            searchTerm: term
        });

        term = term.trim();

        const options = {
            extract: (product) => product.name + product.sku,
        }

        const items = fuzzy.filter(term, this.props.data, options).map((product) => product.original);

        this.setState({
            searchResults: items.slice(0, 20)
        });
    }

    componentDidMount() {
        this.handleSearch('');
    }

    handleClick(itemButtonId) {
        this.props.onButtonItemClick(itemButtonId);
    }

    render() {
        var searchResultsDisplay = this.state.searchResults.map((product) => {
            return (
                <div key={product.id} className="action-card">
                    <a style={{padding: 10, display: "block"}}
                       href="javascript:void(0)"
                       onClick={this.handleSearchItemClick.bind(this, product.id)}>
                        <div className="row">
                            <div className="col-xs-6">{product.name}</div>
                            <div className="col-xs-3">
                                <span className="pull-right">{product.sku}</span>
                            </div>

                            <div className="col-xs-3">
                                <span className="pull-right">{accounting.formatMoney(product.price)}</span>
                            </div>
                        </div>
                    </a>
                </div>
            );
        });

        return (

            <div style={{height: '100%'}}>
                <Search  onSearch={this.handleSearch} searchTerm={this.state.searchTerm}/>


                <div style={{
                    padding: "0 10px 20px",
                    fontWeight: 'bold',
                    fontSize: '125%',
                    maxHeight: '45px',
                    overflow: 'hidden'
                }}>
                    <div className="row">
                        <div className="col-xs-6">Item</div>
                        <div className="col-xs-3">
                            <span className="pull-right">SKU</span>
                        </div>

                        <div className="col-xs-3">
                            <span className="pull-right">Price</span>
                        </div>
                    </div>
                </div>
                <div className="action-card-container pos-card-control"
                     style={{height: 'calc(100% - 94px)'}}>{searchResultsDisplay}</div>
            </div>
        );
    }
}
