import React, {Component} from "react";

export default class ModalContent extends Component {
    render() {
        return (
            <div className={this.props.large ? "pos-modal-content pos-modal-lg" : "pos-modal-content"}>
                {this.props.children}
            </div>
        );
    }
};
