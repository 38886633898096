import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import axios from "../utils/axios";
import {Form} from "react-final-form";

interface CNBProps {
    onSubmit: () => void;
    orderId: number;
}

const postSupplyAll = (orderId: number) => {
    return axios.post<{redirect: string}>(`/supplierorder/supply-all`, {
        supplierOrderId: orderId
    });
}

export const SupplyAllButton = ({onSubmit, orderId}: CNBProps) => {
    const [show, setShow] = useState(false);

    const onSupplyAll = async () => {
        setShow(false);

        await postSupplyAll(orderId).then((e) => {
            onSubmit();
        });
        // TODO error handling outside of global?
    }

    return (
        <React.Fragment>
            <button className="btn btn-primary" onClick={() => setShow(true)}>Receive All</button>

            <Modal show={show} onHide={() => setShow(false)} centered>
                 {/*@ts-ignore}*/}
                <Modal.Header closeButton>
                    <Modal.Title>Receive All Items</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Receive all items in order at the current time?
                    </p>

                    <p>
                        <small>This will supply all items in order at their given price</small>
                    </p>
                </Modal.Body>

                <Modal.Footer >
                    {/* TODO this component should handle the credit note request and close on success */}

                    <button type="button" className="btn btn-secondary" onClick={() => setShow(false)}>
                        Cancel
                    </button>

                    <Form
                        onSubmit={ onSupplyAll}
                        render={({handleSubmit, submitting, pristine, invalid, values, errors}) => (

                            <form onSubmit={handleSubmit}>
                                <button type="submit" className="btn btn-primary">OK</button>
                            </form>
                        )}
                    />
                </Modal.Footer>
            </Modal>

        </React.Fragment>

    );
}
